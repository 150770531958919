import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { CreateMembershipComponent } from './create-membership/create-membership.component';
import { TranslationService } from '../../../providers/services/translation.service';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { SharedModule } from '../../componenets/shared/shared.module';
import { FileService } from '../../../providers/services/file.service';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

const routes: Routes = [
  {
    path: 'membership-form',
    component: CreateMembershipComponent
  }
]
@NgModule({
  declarations: [
    CreateMembershipComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    FileUploadModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  providers: [
    TranslationService,
    FileService
  ]
})
export class MembershipModule { }
