import {NgModule} from "@angular/core";
import {FileUploadComponent} from "./file-upload.component";
import {FileService} from "../../../providers/services/file.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [
    FileUploadComponent
  ],

  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    FileService
  ]
})
export class FileUploadModule{}
