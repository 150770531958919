<p class="red-color">{{this.errorMessage}}</p>
<code-input [isCodeHidden]="false" [initialFocusField]="0"
            [codeLength]="6"
            (codeCompleted)="onCodeCompleted($event)" [disabled]="codeInputDisable"
            #codeInput
            [ngClass]="{'green' : this.codeSuccess ,
                            'red' : ! this.codeSuccess }">
</code-input>

<app-timer [seconds]="0" minutes="1"  (timeExpire)="timeExpire()" *ngIf="timerShow"></app-timer>
<div>
  <button mat-button (click)="resendCode()" [disabled]="!this.resendBtn" class="email-resend-btn">{{"resendCode" | translate}}</button>
</div>
