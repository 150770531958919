<div class="container">
  <div>
    <h1 class="title">{{"signIn" | translate}}</h1>
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
      <p *ngIf="showError" class="red-color">{{"incorrectCredentials" | translate}}</p>
      <p  class="red-color">{{ serverErrorMessage}}</p>
      <div class="column">
        <mat-form-field appearance="outline">
          <mat-label class="label">{{"email" | translate}}</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label class="label">{{"password" | translate}}</mat-label>
          <input [type]="showPass ? 'text' : 'password'" matInput formControlName="password">
          <mat-icon (click)="showPassword($event)" class="icon-showPassword"
                    matSuffix> {{showPass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
      <button [disabled]="disabled" class="button-submit" mat-flat-button>{{"signIn" | translate}}</button>
    </form>
  </div>

</div>
