import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationMessagesConstant } from "../constants/validation-messages.constant";
import { BaseHelperComponent } from "./base.helper-component";

export class FormBaseComponent extends BaseHelperComponent {

  form!: FormGroup;
  submitted: boolean = false;
  errors = [];
  Validation = ValidationMessagesConstant;
  disabled: boolean = false;


  constructor(
    protected fb: FormBuilder) {
    super();
  }

  getControl(name: string): FormGroup {
    return this.form.get(name) as FormGroup;
  }

  hasError(name: string, error: string) {
    let e = this.getControl(name);
    return e && (e.dirty || e.touched) && e.hasError(error);
  }

  hasErrors(name: string) {
    let control = this.getControl(name);
    return control && (control.dirty || control.touched) && control.errors;
  }

  public handleError(validationErrors: any) {
    if (!validationErrors) return;

    Object.keys(validationErrors).forEach(prop => {
      const formControl = this.form.get(prop.charAt(0).toLowerCase() + prop.slice(1));
      if (formControl) {
        formControl.markAsTouched({ onlySelf: true });
        formControl.setErrors({ serverError: validationErrors[prop] });
      } else {
        let controlName = prop.split('.');
        if (controlName.length == 2) {
          let control = this.form.get(`${controlName[0].charAt(0).toLowerCase() + controlName[0].slice(1)}.${controlName[1].charAt(0).toLowerCase() + controlName[1].slice(1)}`);
          if (control) {
            control.markAsTouched({ onlySelf: true });
            control.setErrors({ serverError: validationErrors[prop] })
          }
        }
      }
    })
  }

  public formInvalid() {
    this.submitted = true;
    return this.form.invalid;
  }

  protected resetform() {
    this.submitted = false;
    this.form.reset();
  }

  protected transformServerErrors(error: any) {
    if (!error.errors) return;
    this.errors = []
    Object.keys(error.errors).map((key) => {
      this.errors = this.errors.concat(error.errors[key])
    })
  }

  protected setErrorsToControls(error: any) {
    if (!error.errors) {
      return;
    }
    ;
    Object.keys(error.errors).map((key) => {
      let lowerCaseKey = this.capitalizeFirstLetter(key);
      let control = this.form.get(lowerCaseKey);
      this.form.get(lowerCaseKey)?.setErrors({ serverErrors: error.errors[key] });
    })

  }


  private capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toLowerCase() + text.slice(1);
  }

  protected validateForm() {
    Object.keys(this.form.controls).forEach(field => {
      let control = this.form.get(field);
      if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach(f => {
          let item = this.form.get(`${field}.${f}`);
          item?.markAsTouched({ onlySelf: true });
          item?.updateValueAndValidity({ onlySelf: true });

        })
      }
      control?.markAsTouched({ onlySelf: true });
      control?.updateValueAndValidity({ onlySelf: true });

    });
  }

  clearDate(fieldName: string) {
    this.form.get(fieldName)?.setValue(null);
  }

  getDate(date: Date | string) {
    if (!date) return undefined
    if (typeof date === 'string') {
      return new Date(date)
    }
    return date;
  }
}
