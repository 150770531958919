import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../../../providers/services/title.service';
import { TranslationService } from '../../../../../providers/services/translation.service';

@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent implements OnInit {

  constructor(
    public translateionService: TranslationService,
    private titleService: TitleService
  ) {


    this.titleService.setTitleWithTranslateion('missionAndObjectives')
  }

  ngOnInit(): void {

  }

  texts = [
    'Advocating and representing the interests of the people of Artsakh on Armenian and international platforms.',
    'Protecting the collective dignity and rights of the people of Artsakh.',
    'Drawing international legal and political attention to the interests and collective rights of the people of Artsakh, contributing to their practicality and consistency.',
    'Implementing continuous awareness (informational) efforts on issues related to the interests and collective rights of the people of Artsakh, aimed at Armenian and international decision-makers and broader audiences.',
    'Facilitating individual and institutional accountability for perpetrators and supporters of genocide and other crimes committed against the people of Artsakh.',
    'Implementing efforts to secure reparation for the material and non-material damages suffered by the people of Artsakh.',
    'Protecting the spiritual and cultural heritage, graves, natural resources, personal property and other material values of the people of Artsakh under Azerbaijani occupation.',
    'Protecting and promoting the interests and collective rights of the people of Artsakh through both foreign political and domestic programs and actions of the state bodies of the Republic of Armenia.',
    'Making efforts to safeguard and promote the rights of the state institutions of the Republic of Artsakh and the subjectivity, self-determination and the right of the people of Artsakh to manage their own destiny.',
    'Contributing to the preservation and development of the collective identity and struggle of the people of Artsakh.',
    'Contributing to meeting the needs of the people of Artsakh and ensuring their well-being.',
    'Supporting other actors working towards representing the interests of the people of Artsakh, protecting their rights and satisfying their needs, with the aim of enhancing the effectiveness of their programs in alignment with the Organizations goals and coordinating efforts.',
    'Striving to ensure the return of the people of Artsakh to their homeland and their life there in peaceful, just, dignified and safe conditions, based on the right to self-determination and other fundamental norms of international law.',
  ]

}
