import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHelperComponent } from '../../../../core/helper-components/base.helper-component';
import {
  ArticleBriefResponse, ArticleClient, FileBaseResponse,
  FileType, Language, PaginatedListOfArticleBriefResponse
} from '../../../../web-api-client';
import { TitleService } from '../../../../providers/services/title.service';
import { PageEvent } from '@angular/material/paginator';
import { tap } from 'rxjs';

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss']
})
export class LatestNewsComponent extends BaseHelperComponent implements OnInit {
  articles: ArticleBriefResponse[] = []
  lang!: Language
  imageLoaded = false;
  @Input() showHeadingButton: boolean = true
  @Input() title: string = 'titles.latest-news'
  @Input() pageNumber: number = 1
  @Input() pageSize: number = 3
  @Input() isNewsPage: boolean = false

  constructor(
    private _articleClient: ArticleClient,
    private router: Router,
    private titleService: TitleService,
    private route: ActivatedRoute
  ) {
    super()
  }

  fileType = FileType;
  files: FileBaseResponse[] | undefined = [];
  fileTypeRoute: FileType = FileType.Image;

  ngOnInit(): void {
    this.lang = this.selectedLanguage

    if (this.isNewsPage) {
      this.getQueryParams()
    } else {
      this.get()
    }

    let fileTypeRouteStr = this.route.snapshot.paramMap.get('fileType') ?? "1";
    this.fileTypeRoute = parseInt(fileTypeRouteStr);

    if (this.title == 'header.news') {
      this.titleService.setTitleWithTranslateion('news')
    }
  }

  navigate(id?: number) {
    this.router.navigate([this.currentLangUrl , 'news', id]);
  }

  get() {
    this._subs.add(
      this._articleClient.get(
        this.selectedLanguage,
        this.pageNumber,
        this.pageSize
      ).pipe().subscribe({
        next: (data: PaginatedListOfArticleBriefResponse) => {
          this.articles = data.items!
          if (this.isNewsPage) {
            this.pageNumber = data.pageNumber ?? 1;
            this.totalRecords = data.totalCount ?? 0;
          }
        },
        error: (error: any) => {
          console.log(error)
        }
      })
    )
  }

  totalRecords!: number
  onPageChanged(event: PageEvent) {
    this.pageNumber = ++event.pageIndex;
    this.pageSize = event.pageSize;
    this.get();
  }


  private getQueryParams() {
    this.route.queryParams.pipe(tap(params => {
      this.pageNumber = isNaN(+params['pageNumber']) ? 1 : +params['pageNumber'] - 1;
      this.pageSize = params['pageSize'] ?? 10
    })).subscribe(() => {
      this.get();
    });
  }

}
