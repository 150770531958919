import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {HeaderComponent} from "./header/header.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {InformDialogComponent} from "./inform-dialog/inform-dialog.component";
import {MaterialModule} from "../../material.module";
import {HeadingComponent} from "./heading/heading.component";
import {TranslateModule} from "@ngx-translate/core";
import { HomeModule } from "../modules/home/home.module";
import { SharedModule } from "../componenets/shared/shared.module";
import { FooterComponent } from './footer/footer.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { LogoComponent } from './logo/logo.component';


@NgModule({
  declarations: [
    HeaderComponent,
    ConfirmDialogComponent,
    InformDialogComponent,
    HeadingComponent,
    FooterComponent,
    DialogHeaderComponent,
    LogoComponent
  ],
  exports: [
    HeaderComponent,
    ConfirmDialogComponent,
    InformDialogComponent,
    HeadingComponent,
    FooterComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    HomeModule,
    SharedModule
  ],
  providers: []

})
export class WidgetsModule {
}
