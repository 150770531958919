import {Component, inject, isDevMode, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";
import {ComponentBindingService} from "../../providers/services/component-binding.service";
import {InformMessagesConstant} from "../constants/inform-messages.constant";
import {Language} from "../../web-api-client";
import {AppItems} from "../constants/app.items";
import {DisplayLanguage} from "../constants/displayLanguage";
import { DeviceUtil } from "../utils/device.util";

@Component({
  selector: 'base-helper',
  template: ""
})
export class BaseHelperComponent implements OnDestroy {
  isLoading = false;
  informMessages = InformMessagesConstant;

  selectedLanguage : Language = Language.En;

  currentLangUrl!: string
  reArmeniaUrl!:string

  protected _subs: Subscription = new Subscription();
  protected location = inject(Location)
  protected componentBindingService = inject(ComponentBindingService);

  constructor() {
    let lang = localStorage.getItem(AppItems.Lang);
    if(lang){
      this.selectedLanguage = this.convertLangStrToEnum(lang);
    }
    this.currentLangUrl = localStorage.getItem(AppItems.Lang) || localStorage.getItem('default-lang')!

    this.reArmeniaUrl = `https://rearmenia.com/${this.currentLangUrl}/fundraisers/cedca060-7ad4-4953-9eb7-339c931a5d61?tab=story`;
  }

  get isMobileDevice(): boolean {
    return DeviceUtil.isMobileDevice();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  back() {
    this.location.back();
  }

  navigateToDonation() {
    window.open(this.reArmeniaUrl, '_blank');
  }

  isDevelopment() {
    return isDevMode();
  }

  public  convertLangStrToEnum(lang :string){
    if(lang == DisplayLanguage.En)
      return Language.En;
    if(lang == DisplayLanguage.Ru)
      return Language.Ru;
    if(lang == DisplayLanguage.Hy)
      return Language.Hy;

    return Language.None
  }

}

