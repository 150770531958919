<ng-container *ngIf="isSignedIn; else unauthorizedMenu">
  <div class="navbar">
    <div class="container-90">
      <app-logo [isFromHeader]="true"
                [customClass]="'logo'"></app-logo>
      <div class="d-flex">
        <ul>
          <li>
            <a href="{{this.currentLangUrl}}/gallery">{{"gallery" | translate}}</a>
          </li>
          <li>
            <a href="{{this.currentLangUrl}}/articles">{{"articles"  | translate}}</a>
          </li>
          <li>
            <a href="{{this.currentLangUrl}}/user-management">{{"users" | translate}}</a>
          </li>
          <li>
            <a href="{{this.currentLangUrl}}/memberships/{{this.membershipStatus}}">{{"membership" | translate}}</a>
          </li>
          <li>
            <a href="{{this.currentLangUrl}}/subscriptions">{{"work-subscriptions" | translate}}</a>
          </li>
          <li>
            <a href="{{this.currentLangUrl}}/connections">{{"connection" | translate}}</a>
          </li>
        </ul>
        <ul>
          <app-nav-language [isFromHeader]="true"></app-nav-language>
          <li style="margin-top: -3.5px;">
            <mat-icon (click)="signOut()">logout</mat-icon>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #unauthorizedMenu>
  <div class="navbar" *ngIf="!isMobileDevice; else mobileHeader">
    <div class="container-90" style="padding-left:20px;padding-right:20px;">
      <app-logo [isFromHeader]="true"
                [customClass]="'logo'"></app-logo>
      <div>
        <ul style="align-items:center">
          <button class="menu-button mat-button" mat-button [matMenuTriggerFor]="menu">
            <a class="menu">
              {{'header.about-us' | translate}}
              <mat-icon>expand_more</mat-icon>
            </a>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="[this.currentLangUrl + '/objectives']">{{'header.mission-and-objectives' | translate}}</button>
            <button mat-menu-item [routerLink]="[this.currentLangUrl + '/management']">{{'header.staff' | translate}}</button>
            <button (click)="navigate('/charter')" mat-menu-item>{{'header.charter' | translate}}</button>
          </mat-menu>

          <li>
            <a (click)="navigate('/all-news')">{{'header.news' | translate}}</a>
          </li>

          <li>
            <a (click)="navigate('/membership-form')">{{'btn.join-us' | translate}}</a>
          </li>

          <li>
            <a (click)="navigate('/contact-us-form')">{{'header.contact-us' | translate}}</a>
          </li>

          <li>
            <button [style.width.px]="getWidth" (click)="navigate('/subscription-form/dialog')" mat-button type="submit" class="btn-orange">
              {{'header.subscribe' | translate}}
            </button>
          </li>

          <li>
            <button [style.width.px]="getWidth" (click)="navigateToDonation()" mat-raised-button type="submit" class="btn-blue-shadow">
              {{'btn.donate-now' | translate}}
            </button>
          </li>

          <app-nav-language [isFromHeader]="true"></app-nav-language>

          <ng-container *ngIf="isSignedIn">
            <li *ngIf="isDevelopment()">
              *
              <a href="/api" target="_blank">API</a>
            </li>
            <li>
              <mat-icon (click)="signOut()">logout</mat-icon>
            </li>
          </ng-container>

        </ul>
      </div>
    </div>
  </div>

  <ng-template #mobileHeader>
    <div class="navbar">
      <div class="container-90">
        <app-logo [isFromHeader]="true"
                  [customClass]="'logo'"></app-logo>
        <div style="padding-right:8%;">
          <mat-icon (click)="openHeader()">menu</mat-icon>
        </div>
      </div>
    </div>
  </ng-template>

</ng-template>

