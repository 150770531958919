import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppItems } from "../../core/constants/app.items";
import { DisplayLanguage } from "../../core/constants/displayLanguage";

@Injectable()
export class TrackLanguageChangeUrlService {
  currentLanguage!: string
  languages = [DisplayLanguage.En, DisplayLanguage.Hy, DisplayLanguage.Ru]

  constructor(
    private router: Router
  ) {
  }

  setLanguageUrl(url: string, value: string) {
    this.currentLanguage = localStorage.getItem(AppItems.Lang) || DisplayLanguage.En;
    let lang = value || 'en';



    if (this.currentLanguage?.toLowerCase() !== lang.toLowerCase()) {
      lang = value.toLowerCase();

      if(!this.languages.includes(lang))
      {
        lang = DisplayLanguage.En;
      }

      localStorage.setItem(AppItems.Lang, lang);
      //this.router.navigate([lang + url]);
      window.location.reload();
    }
    //else if (!selectedLanguage && this.currentLanguage?.toLowerCase() !== lang) {
    //  localStorage.setItem(AppItems.Lang, lang);
    //  window.location.reload();
    //}
    return;

  }


}
