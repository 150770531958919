import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit, AfterViewInit {

  @Input() seconds: any;
  @Input() minutes: any;
  @Output() timeExpire = new EventEmitter<boolean>();

  interval: any;
  secondText: any ;
  minuteText: any;

  constructor() {
  }

  ngOnInit(): void {
    this.setTexts();
  }


  ngAfterViewInit(): void {
    this.timer();
  }

  public timer() {
    this.interval = setInterval(() => {

      if (this.minutes > -1) {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          this.seconds = 59;
          this.minutes--;
        }

        if (this.minutes != -1) {
          clearInterval();
          this.setTexts();
        }
      } else {
        this.timeExpire.emit(true);
      }
    }, 1000);
  }


  private setTexts(){
    this.minuteText = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.secondText = this.seconds < 10 ? '0' + this.seconds : this.seconds;
  }


  public  clearInterval(){
    clearInterval(this.interval);
  }
}
