import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBaseComponent} from "../../../../core/helper-components/form.base.component";
import {FormBuilder, Validators} from "@angular/forms";
import {AuthClient, IdentityClient, SignInCommand} from "../../../../web-api-client";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "../../change-password/change-password.component";
import {IdentityService} from "../../../../providers/services/identity.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-sign-in-credentials',
  templateUrl: './sign-in-credentials.component.html',
  styleUrls: ['./sign-in-credentials.component.css']
})
export class SignInCredentialsComponent extends FormBaseComponent implements OnInit {

  showPass = false;
  showError : boolean = false;
  @Output() emailEmit = new EventEmitter<string>();
  serverErrorMessage : string = "";
  returnUrl! : string;

  constructor(
    private identityClient: IdentityClient,
    private dialog : MatDialog,
    private identityService : IdentityService,
    private router: Router,
    private route: ActivatedRoute,
    private authClient : AuthClient,
    fb: FormBuilder) {
    super(fb);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main';
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit(){
    this.showError = false;
    this.validateForm();
    if(this.formInvalid()){
      return;
    }

    //This code only for testing
/*    let cmd = <SignInCommand> this.form.value;
    this.identityClient.signIn(cmd)
      .subscribe(_ => {
        console.log(_)
        this.identityService.login(_);
        this.router.navigateByUrl(this.returnUrl);
      })*/

    let cmd = <SignInCommand> this.form.value;
    this._subs.add(
      this.authClient.signIn(cmd)
        .subscribe(_ =>{
          if(_.valid && _.tempPassword){
            this.openChangePasswordDialog(cmd.email);
          }
          else if(!_.valid){
            this.showError = true;
          }
          else{
            this.emailEmit.emit(cmd.email);
          }
        }, (error) => {
          this.serverErrorMessage = error.message;
          console.log(error.message)
        //  this.showError = true;
        })
    )
  }

  showPassword(event: any) {
    event.stopPropagation();
    this.showPass = !this.showPass;
  }

  private openChangePasswordDialog(email : any){
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        data: {
          email: email
        },
        width: '350px',
        maxHeight: '90vh',
        autoFocus: false
      });
      this._subs.add(
        dialogRef.afterClosed().subscribe(_ => {
          if (_){
            this.emailEmit.emit(email);
          }
        })
      );
  }

  private initForm(){
    this.form = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
}
