import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBaseComponent } from '../../../../../core/helper-components/form.base.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent extends FormBaseComponent implements OnInit {

  constructor(
    private router: Router,
    fb: FormBuilder
  ) {
    super(fb)
  }

  ngOnInit(): void {
    this.initForm()
  }

  submit() {
  
  }

  navigate() {
    const url = this.currentLangUrl + '/membership-form';
    this.router.navigate([url]);
  }

  private initForm() {
    this.form = this.fb.group({
      search: [''],
    });
  }

}
