import { HttpClient } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import { BehaviorSubject, Observable } from 'rxjs';
import {AppItems} from "./core/constants/app.items";
import {DisplayLanguage} from "./core/constants/displayLanguage";
import { DeviceUtil } from './core/utils/device.util';
import {AuthService} from "./providers/services/auth.service";
import { TranslationService } from './providers/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements  OnInit{

  isSignedIn: boolean = false;
  languages = [DisplayLanguage.En, DisplayLanguage.Hy, DisplayLanguage.Ru]

  private locationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public location$: Observable<any> = this.locationSubject.asObservable();

  constructor(private translateService: TranslateService,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {

    this.getLocation()

    //setTimeout(() => {
      const defaultLang = localStorage.getItem('default-lang')
      this.translateService.setDefaultLang(defaultLang!);
      this.translateService.use(localStorage.getItem(AppItems.Lang) || defaultLang!);
    //}, 100)

    this.translateService.onLangChange.subscribe(() => {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 500)

    });

    this.isSignedIn = this.authService.getSignIn();
  }

  get isMobileDevice(): boolean {
    return DeviceUtil.isMobileDevice();
  }

  isLoading: boolean = false
  getLocation(): void {

    if (!localStorage.getItem('default-lang')) {
      this.isLoading = true

      this.http.get('https://ipapi.co/json/').subscribe(
        (response: any) => {
          localStorage.setItem('default-lang', response.languages)
          this.router.navigateByUrl(this.isValidLanguage(response.languages) ? response.languages : 'en')
          this.isLoading = false
        },
        (error) => {
          console.error('Error fetching location:', error);
          this.isLoading = false
        }
      );
    }

  }

  ngOnInit(): void {
    // Subscribe to route parameter changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Access the lang parameter
        const lang = this.route.snapshot.firstChild?.params['lang'];

        let defaultLanguage = localStorage.getItem(AppItems.Lang);
        if (defaultLanguage != lang && this.isValidLanguage(lang)) {
          localStorage.setItem(AppItems.Lang, lang);
          this.translateService.setDefaultLang(lang);
          this.translateService.use(lang);
          window.location.reload();
        }
        else if (lang && !this.isValidLanguage(lang)) {
          this.router.navigateByUrl('page-notfound')
        }
      }
    });
  }

  private isValidLanguage(lang: string): boolean {
    return this.languages.includes(lang);
  }
}
