<div class="container">

  <!--<div class="flex-center">
    <div class="context-header">
      <h2 class="title">{{ '' | translate }}</h2>
      <a style="font-size:15px;">
        <span style="">{{ 'btn.see-more' | translate }}</span>
        <mat-icon style="vertical-align:sub;padding-top:2px;">navigate_next</mat-icon>
      </a>
    </div>
    <mat-divider style="margin-top:10px;"></mat-divider>
  </div>-->

  <app-landing-heading [title]="title"
                       [showButton]="showHeadingButton"
                       [buttonText]="'btn.see-more'"
                       [isNewsPage]="isNewsPage"
                       [navigationUrl]="this.currentLangUrl + '/all-news'"></app-landing-heading>

  <div class="d-flex-center" style=" gap: 10px;">
    <div class="context" *ngFor="let item of articles">
      <div class="inner-context">
        <!--<div class="loading-overlay" *ngIf="!imageLoaded">
          <div class="loading-dots"></div>
          <div class="loading-dots"></div>
          <div class="loading-dots"></div>
        </div>-->
        <app-file-display *ngIf="item.path" [fileType]="fileTypeRoute"
                          [altImg]="item.title!"
                          [customClass]="'latest-news'"
                          [filePath]="item.path"></app-file-display>
        <p>{{ item?.publishDate | date: 'dd/MM/yyyy' }}</p>
        <a (click)="navigate(item?.articleId)">
          <h3 *ngIf="!isNewsPage" class="news-link">{{item?.title}}</h3>
          <h2 *ngIf="isNewsPage" class="news-link">{{item?.title}}</h2>
        </a>
      </div>
    </div>

  </div>
  <app-paging *ngIf="isNewsPage"
              [totalRecords]="this.totalRecords"
              [pageSize]="this.pageSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              (onPageChanged)="onPageChanged($event)">
  </app-paging>
</div>
<!--<app-share-buttons></app-share-buttons>-->
