<div class="bg-light-blue">
  <div class="container-90">
    <h1 class="title dark-blue">{{ mainTitle | translate }}</h1>
  </div>
</div>

<div class="cards-container">
  <p class="sub-title">{{title | translate}}</p>
  <a *ngFor="let item of articleNavigations; let i = index"
     [href]="href + item">
    <mat-card>
      <mat-card-content style="display:flex;">
        <mat-icon class="dark-blue">notes</mat-icon>
        <span class="dark-blue sub-item"> {{ translationService.getTranslation(jsonKey + '-' + (i + 1)) }} </span>
      </mat-card-content>
    </mat-card>
  </a>
</div>
