import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../../../providers/services/file.service';

@Component({
  selector: 'app-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss']
})
export class ImageDisplayComponent implements OnInit {
  @Input() path!: string | undefined;
  @Input() customStyle!: string | undefined;
  imageData: SafeUrl | null = null;

  constructor(private sanitizer: DomSanitizer,
    private fileService: FileService) { }

  ngOnInit(): void {
    this.imageData = this.sanitizer.bypassSecurityTrustUrl(this.path!);
    //if (this.id) {
    //  this.fileService.downloadFile(this.id).subscribe(
    //    (data: Blob) => {
    //      const objectURL = URL.createObjectURL(data);
    //      this.imageData = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    //    },
    //    (error: any) => {
    //      console.error('Error downloading image:', error);
    //    }
    //  );
    //}
  }

  ngOnChanges(): void {

  }
}
