import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../../material.module";
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '../../../providers/services/translation.service';
import { CreateSubscriptionComponent } from './create-subscription/create-subscription.component';
import { SharedModule } from '../../componenets/shared/shared.module';
import { SubscriptionConfirmedComponent } from './subscription-confirmed/subscription-confirmed.component';
import { SubscriptionDialogComponent } from './subscription-dialog/subscription-dialog.component';

const routes: Routes = [
  {
    path: 'dialog',
    component: SubscriptionDialogComponent
  },
];

@NgModule({
  declarations: [
    CreateSubscriptionComponent,
    SubscriptionConfirmedComponent,
    SubscriptionDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SharedModule
  ],
  exports: [CreateSubscriptionComponent],
  providers: [TranslationService]
})
export class SubscriptionModule { }
