import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function yearValidator(currentYear: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const yearPattern = /^[12]\d{3}$/;
    if (!yearPattern.test(value)) {
      return { invalidYear: true };
    }
    const inputYear = parseInt(value, 10);
    if (inputYear > currentYear) {
      return { futureYear: true };
    }
    return null;
  };
}
