import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseHelperComponent } from '../../../core/helper-components/base.helper-component';
import { Language } from '../../../web-api-client';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent extends BaseHelperComponent implements OnInit {
  lang!: Language
  @Input() isFromHeader: boolean = false
  @Input() customClass!: string
  srcHeader!: string
  srcFooter!: string

  constructor(
    private router: Router,
  ) {
    super()
  }
  logoPrefixMap: Record<Language, string> = {
    [Language.En]: 'en',
    [Language.Ru]: 'ru',
    [Language.Hy]: 'hy',
    [Language.None]: '',
  };
  footerPrefixMap: Record<Language, string> = {
    [Language.En]: 'en',
    [Language.Ru]: 'en',
    [Language.Hy]: 'hy',
    [Language.None]: '',
  };
  ngOnInit(): void {
    this.lang = this.selectedLanguage;
    const logoPrefix: string = this.logoPrefixMap[this.lang];
    const footerPrefixMap: string = this.footerPrefixMap[this.lang];

    this.srcHeader = `./assets/images/logo_${logoPrefix}.png`;
    this.srcFooter = `./assets/images/logo-footer-${footerPrefixMap}.svg`;
  }


  navigateToHome() {
    const url = this.currentLangUrl;
    this.router.navigate([url])
  }

}
