import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../../providers/services/translation.service';
import { Location } from '@angular/common';
import { SubscriptionConfirmedComponent } from '../../../modules/subscriptions/subscription-confirmed/subscription-confirmed.component';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseHelperComponent } from '../../../../core/helper-components/base.helper-component';

@Component({
  selector: 'app-landing-heading',
  templateUrl: './landing-heading.component.html',
  styleUrls: ['./landing-heading.component.scss']
})
export class LandingHeadingComponent extends BaseHelperComponent implements OnInit {

  @Input() title!: string
  @Input() showButton: boolean = false
  @Input() buttonText!: string
  @Input() navigationUrl!: string
  @Input() showTitleButton : boolean = false
  @Input() isNewsPage : boolean = false
  @Input() showMatDivider : boolean = true

  constructor(
    private router: Router,
    public translationService: TranslationService,
    private _location: Location,
    public dialogRef: MatDialogRef<SubscriptionConfirmedComponent>,
  ) {
    super()
  }

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
  }

  naviateTo(url: string) {
    this.router.navigate([url])
  }

  navigateToHome() {
    this.dialogRef.close(true)
    this.router.navigate(['/' + this.currentLangUrl]);
  }

}
