<div>
  <mat-divider></mat-divider>
  <!--class="bg-light-blue"-->
  <div class="container-90" style="justify-content:start">
    <!--<button mat-icon-button (click)="goBack()" matTooltip="{{'btn.go-back' | translate}}">
      <mat-icon class="dark-blue">arrow_back</mat-icon>
    </button>-->
    <h2 class="title dark-blue">{{this.title | translate}}</h2>
  </div>
</div>

