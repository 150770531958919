<div class="bg-light-blue">
  <div class="container-90">
    <div style="display:flex">
      <!--<button mat-icon-button (click)="goBack()" style="margin-top:6px;" matTooltip="{{'btn.go-back' | translate}}">
        <mat-icon class="dark-blue">arrow_back</mat-icon>
      </button>-->
      <h1 class="title dark-blue">{{content?.title}}</h1>
    </div>
  </div>
</div>

<div [ngClass]="isMobileDevice ? 'padding' : 'container-90'">
  <div>
    <p>{{ content?.publishDate | date: 'dd/MM/yyyy' }}</p>
    <div [ngClass]="isMobileDevice ? 'main-img-mobile' : 'main-img'">
      <app-file-display style="cursor:pointer"
                        *ngIf="content?.mainFile?.path" [fileType]="1"
                        [altImg]="content.title!"
                        [customClass]="'details'"
                        [filePath]="content?.mainFile?.path!"
                        [allowFullScreen]="true"></app-file-display>
    </div>
    <div class="text-container">
      <p>
      <p [innerHTML]="content?.description | safeHtml">
      </p>
    </div>

  </div>

</div>



<div class="container-90">
  <div>

    <div *ngIf="imageObject.length > 0" style="width: 100%;margin:60px 0 60px; display: flex;">
      <div #container class="images-container">
        <ng-image-slider #nav
                         [images]="imageObject"
                         [infinite]="false"
                         [manageImageRatio]="true"
                         [autoSlide]="1"
                         [imageSize]="{width:isMobile ? '100%' : '25%', height: 200}"
                         [slideImage]="isMobile ? 1 : 3"></ng-image-slider>

      </div>
    </div>

    <iframe *ngIf="URL" [src]="URL" frameborder="0" allowfullscreen></iframe>
    <app-share-buttons *ngIf="this.content"
                       [img]="content?.mainFile?.copyPath"
                       [title]="content?.title"
                       [url]="this.currentUrl"
                       [description]="'Artsakh Union'"></app-share-buttons>
  </div>
</div>
