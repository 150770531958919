import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginator,
  MatPaginatorDefaultOptions,
  PageEvent
} from "@angular/material/paginator";

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent  implements  AfterViewInit {

  @Input() totalRecords!: number;
  @Input() pageSize!: number;
  @Input() pageSizeOptions!: number[];

  @Output() onPageChanged = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  previousPageSize!: number | undefined;

  constructor(@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private defaultPageSize: MatPaginatorDefaultOptions) {
    this.previousPageSize = defaultPageSize.pageSize;

  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
  }

  pageChanged(event: PageEvent) {
    if (this.previousPageSize !== event.pageSize) {
      this.previousPageSize = event.pageSize;
      event.pageIndex = 0;
      event.previousPageIndex = 0;
      this.paginator.pageIndex = 0;
    }
    this.paginator.length = this.totalRecords;
    this.onPageChanged.emit(event);
  }
}
