export const ValidationPatternsConstant = {
  PhoneNumber: /^[+0-9]{6,30}$/,
  ArmLetters: /^[Ա-Ֆա-և- ]+$/,
  OnlyDigits: /^[0-9]+$/,
  FractionDigits: /^[0-9]*\.?[0-9]{1,2}$/,
  FullFractionDigits: /^-?[0-9]*\.?[0-9]{1,2}$/,
  Address: /^(?=.*[Ա-և])[Ա-և- ․.,/0-9]+$/,
  Year: /^[0-9]{4}$/,
  EngLettersAndDigits: /^[0-9a-zA-Z]+$/,
  EngLetters: /^[A-Z]+$/,
  BankNumber: /^[0-9]{1,16}$/,
  UserName: /^[a-z][a-z0-9]{3,11}$/,
  Max255: /^.{1,255}$/,
  Email: /^[^\s@]+@[^\s@]+\.[^\s@]+[^\.~!@#$%^&*()_+-./,'";:\[\]{}]+$/,
  Password: ""
}
