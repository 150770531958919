import {Component, Inject, OnInit} from '@angular/core';
import {FormBaseComponent} from "../../../core/helper-components/form.base.component";
import {FormBuilder, Validators} from "@angular/forms";
import {ChangeTempPasswordCommand, IdentityClient, ResetPasswordCommand} from "../../../web-api-client";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslatedMessageService} from "../../../providers/services/translated-message.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends FormBaseComponent implements OnInit {

  email : string = "";
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private  identityClient : IdentityClient,
    private messageService : TranslatedMessageService,
    fb: FormBuilder) {
    super(fb);
    this.email = this.data?.email;
  }

  ngOnInit(): void {
    this.initForm()
  }

  onSubmit(){
    this.validateForm()
    if(this.formInvalid()){
      return;
    }

    let cmd = new ChangeTempPasswordCommand();
    cmd.email = this.email;
    cmd.newPassword = this.form.get('newPassword')?.value;
    this._subs.add(
      this.identityClient.changeTempPassword(cmd)
        .subscribe(_ => {
            this.messageService.notifySuccessMessage('successfulAction');
            this.dialogRef.close(true);
        }, (error) => {

        })
    )
  }

  close(){
    this.dialogRef.close();
  }
  private initForm(){
    this.form = this.fb.group({
      newPassword: ['', Validators.required],
      repeatPassword: ['', Validators.required]
    });
  }

}
