import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBaseComponent} from "../../../../core/helper-components/form.base.component";
import {FormBuilder, Validators} from "@angular/forms";
import {CheckTempCodeCommand, ErrorResponse, IdentityClient, TempCodeClient} from "../../../../web-api-client";
import {IdentityService} from "../../../../providers/services/identity.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {CodeInputComponent} from "angular-code-input";
import {AuthService} from "../../../../providers/services/auth.service";

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css']
})
export class EmailVerifyComponent extends FormBaseComponent implements OnInit {

  @Input() email! : string;
  codeInputDisable: boolean = false;
  codeSuccess: boolean = false;
  errorMessage : string | undefined = "";
  @ViewChild('codeInput') codeInput: CodeInputComponent = new CodeInputComponent();
  timerShow : boolean = true;
  resendBtn : boolean = false;
  returnUrl : string;
  constructor(
    private tempCodeClient : TempCodeClient,
    private identityClient : IdentityClient,
    private identityService: IdentityService,
    private router : Router,
    private route: ActivatedRoute,
    private authService : AuthService,
    private notifier : NotifierService,
    fb: FormBuilder) {
    super(fb);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || `${this.currentLangUrl}/main`;
    if(this.returnUrl == "sign-in"){
      this.returnUrl = `${this.currentLangUrl}/main`;
    }
    console.log(this.returnUrl)
  }

  ngOnInit(): void {
    this.sendEmail();
  }

  timeExpire() {
    this.timerShow = false;
    this.resendBtn = true;
     this.clearCode();
  }
   onCodeCompleted(code : string){
    let cmd = new CheckTempCodeCommand();
    cmd.email = this.email;
    cmd.tempCode = code;

    this._subs.add(
      this.identityClient.checkCode(cmd)
        .subscribe(_ => {
          if(_.tempCodeFailed){

          }
          else{
            this.codeSuccess = true;
            this.authService.setSignIn();
            this.authService.setTokens(_);
            window.location.href = this.returnUrl;
            //  this.router.navigate([this.returnUrl], { skipLocationChange: true });
          }
        }, (error : ErrorResponse)  =>{
            this.errorMessage = error.message;
            this.codeInputDisable = true;
        })
    )
  }

  private clearCode() {
    if (this.codeInput) {
      this.codeInput.reset();
    }
  }


  public resendCode()
  {
    this._subs.add(
      this.tempCodeClient.resendCode(this.email)
        .subscribe(_ =>  {
          this.resendBtn = false;
          this.timerShow = true;
        }, (_ : ErrorResponse)=> {
          this.errorMessage = _.message;
          this.resendBtn = false;

        }))
  }

  private sendEmail(){
    console.log('resend')
    this.errorMessage = "";
    this._subs.add(
       this.tempCodeClient.sendCode(this.email)
         .subscribe(_ => {
            this.resendBtn = false;
            this.timerShow = true;
         }, (_ : any)=> {
             this.notifier.notify('error', _.Message);
           console.log(_)
          // this.errorMessage = _.message;
           this.resendBtn = false;

         })
    )
  }


}
