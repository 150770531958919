import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { BaseHelperComponent } from '../../../core/helper-components/base.helper-component';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.css']
})
export class DialogHeaderComponent extends BaseHelperComponent implements OnInit {

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogHeaderComponent>,
  ) {
    super()
  }

  ngOnInit(
 
  ): void {
  }

  panelData = [
    { route: '/all-news', label: 'header.news' },
    { route: '/membership-form', label: 'btn.join-us' },
    { route: '/contact-us-form', label: 'header.contact-us' }
  ];

  subMenu = [
    { route: '/objectives', label: 'header.mission-and-objectives' },
    { route: '/management', label: 'header.staff' },
    { route: '/charter', label: 'header.charter' },
  ]

  navigateComponent(link: string) {
    const url = this.currentLangUrl + link;
    this.router.navigate([url]);
    this.dialogRef.close(true)
  }

  navigate(link: string) {
    this.router.navigate([link])
    this.dialogRef.close(true)
  }

  navigateTo() {
    const url = this.currentLangUrl + '/membership-form';
    this.router.navigate([url]);
    this.dialogRef.close(true)
  }

}
