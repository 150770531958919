<form [formGroup]="form" (ngSubmit)="onSubmit()"  enctype="multipart/form-data">
      <input type="file" formControlName="files" name="files" multiple (change)="onFileSelected($event)"/>
  <div>
    <button type="submit">{{ "upload" | translate}}</button>
  </div>
</form>


<div *ngFor="let f of selectedFiles">
  <div>{{f.name}} <button (click)="delete(f)" mat-icon-button><mat-icon>delete</mat-icon></button></div>
</div>
