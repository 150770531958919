import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../../../providers/services/translation.service';

@Component({
  selector: 'app-terms-context',
  templateUrl: './terms-context.component.html',
  styleUrls: ['./terms-context.component.scss']
})
export class TermsContextComponent implements OnInit {

  @Input() mainTitle!: string
  @Input() title!: string
  @Input() href!: string
  @Input() articleNavigations!: any
  @Input() jsonKey!: string

  constructor(
    public translationService: TranslationService
  ) { }

  ngOnInit(): void {
  }

}
