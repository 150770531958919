import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../../material.module";
import { HomeComponent } from './home.component';
import { LatestNewsComponent } from './latest-news/latest-news.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '../../../providers/services/translation.service';
import { SharedModule } from '../../componenets/shared/shared.module';
import { ThanksDialogComponent } from './thanks-dialog/thanks-dialog.component';
import { MainPageModule } from './main-page/main-page.module';
import { DetailsComponent } from './latest-news/details/details.component';
import { FileDisplayModule } from '../file-display/file-display.module';
import { SubscriptionModule } from '../subscriptions/subscription.module';
import { TrackLanguageChangeUrlService } from '../../../providers/services/track-language-change-url.service';
import { MembershipModule } from '../membership/membership.module';
import { SafeHtmlPipe } from "../../../core/pipes/safeHtml.pipe";
import { NewsComponent } from './news/news.component';
import { TitleService } from '../../../providers/services/title.service';
import { PagingModule } from '../paging/paging.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'latest-news',
    component: LatestNewsComponent
  },
  {
    path: 'news/:id',
    component: DetailsComponent
  },
  {
    path: 'all-news',
    component: NewsComponent
  }
];

@NgModule({
  declarations: [
    HomeComponent,
    DetailsComponent,
    LatestNewsComponent,
    LandingPageComponent,
    ThanksDialogComponent,
    NewsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    MainPageModule,
    FileDisplayModule,
    SubscriptionModule,
    MembershipModule,
    SafeHtmlPipe,
    PagingModule
  ],
  exports: [HomeComponent, LatestNewsComponent],
  providers: [
    TranslationService,
    TrackLanguageChangeUrlService,
    TitleService
  ]
})
export class HomeModule { }
