import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthService} from "../../../providers/services/auth.service";
import {Observable, of} from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthenticationGuard implements CanActivate {

  constructor(private authorize: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)  : Observable<boolean> {
    if(this.authorize.getSignIn())
      return of(false)
    return of(true);
  }
}
