import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FileType} from "../../../web-api-client";
import {FileService} from "../../../providers/services/file.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.css']
})
export class FileDisplayComponent implements OnInit {

  @Input() filePath! : string;
  @Input() fileType!: FileType;
  @Input() customClass: string = 'img-size'
  @Input() altImg: string = 'Downloaded Image'
  @Input() allowFullScreen: boolean = false

  imageData: SafeUrl | null = null;
  constructor(
    private sanitizer: DomSanitizer,
    private fileService: FileService) { }

  ngOnInit(): void {
    if (this.filePath) {
      this.fileService.downloadFile(this.filePath).subscribe(
        (data: Blob) => {
          const objectURL = URL.createObjectURL(data);
          this.imageData = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        },
        (error : any) => {
        }
      );
    }
  }

  isFullScreen: boolean = false;

  @ViewChild('imgElement') imgElement!: ElementRef;

  toggleFullScreen(event: MouseEvent) {
    if (!this.isFullScreen && this.allowFullScreen) {
      if (this.imgElement.nativeElement.requestFullscreen) {
        this.imgElement.nativeElement.requestFullscreen();
      } else if (this.imgElement.nativeElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        this.imgElement.nativeElement.webkitRequestFullscreen();
      } else if (this.imgElement.nativeElement.msRequestFullscreen) { /* IE/Edge */
        this.imgElement.nativeElement.msRequestFullscreen();
      }
      this.isFullScreen = true;
    }
    else {
      this.exitFullScreen()
    }

  }
  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    this.isFullScreen = false;
  }

}
