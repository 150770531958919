<div class="bg-light-blue">
  <div class="container-90" style="height:70px;">
    <h1 class="title dark-blue">{{'membership-title' | translate}}</h1>
  </div>
</div>

<div class="container-90">
  <div style="font-size:16.5px;line-height:22px;" class="text-align-justify">
    <p><i>{{'membership-text.dear-visitor' | translate}}</i></p>
    <p>{{'membership-text.fill-out-application' | translate}}</p>
    <p>
      {{'membership-text.may-become-members' | translate}}
      <a (click)="navigate('/contact-us-form')">{{'membership-text.contact-us-link' | translate}}</a>
      {{'membership-text.contact-us-text' | translate}}
    </p>
    <p>
      {{'membership-text.opportunity' | translate}}
      <a [href]="this.reArmeniaUrl" target="_blank">{{'membership-text.opportunity-link' | translate}}</a>
    </p>
    <p>{{'membership-text.confidential' | translate}}</p>
  </div>
</div>

<div class="container-90">
  <div class="form-conntainer">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form">
        <div class="item">

          <mat-form-field appearance="outline">
            <mat-label>1 {{'membership-form.state' | translate}}</mat-label>
            <mat-select formControlName="status" (valueChange)="stateValueChange($event)">
              <mat-option *ngFor="let item of personStatus; let i = index"
                          [value]="i+1">
                {{item}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('status', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>2 {{'membership-form.firstname' | translate}}</mat-label>
            <input matInput autocomplete="off" formControlName="firstname" required />
            <mat-error *ngIf="hasError('firstname', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>2.1 {{'membership-form.lastname' | translate}}</mat-label>
            <input matInput autocomplete="off" formControlName="lastname" required />
            <mat-error *ngIf="hasError('lastname', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>2.2 {{'membership-form.b-day' | translate}}</mat-label>
            <input type="text" matInput formControlName="birthYear" maxlength="4" [matAutocomplete]="auto" (input)="filterYears($event)">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of showedYears" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="hasError('birthYear', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
            <mat-error *ngIf="hasError('birthYear', 'invalidYear')">
              {{'ValidationMessages.valid-year' | translate}}
            </mat-error>
            <mat-error *ngIf="hasError('birthYear', 'futureYear')">
              {{'ValidationMessages.future-year' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>2.3 {{'membership-form.email' | translate}}</mat-label>
            <input matInput autocomplete="off" formControlName="email" />
            <mat-error *ngIf="hasError('email', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
            <mat-error *ngIf="hasError('email', 'pattern')">
              {{'ValidationMessages.email' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>2.4 {{'membership-form.phone' | translate}}</mat-label>
            <input matInput autocomplete="off" formControlName="phone" maxlength="20" />
            <mat-error *ngIf="hasError('phone', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
            <mat-error *ngIf="hasError('phone', 'pattern')">
              {{'ValidationMessages.phone-pattern' | translate}}
            </mat-error>
          </mat-form-field>

          <ng-container *ngIf="hideLiveStateData">
            <p style="font-size:14px;">{{numberStartsFromLiveState}} {{'membership-form.live-state' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{'membership-form.live-state-input' | translate}}</mat-label>
              <!--<mat-select formControlName="liveState" (valueChange)="liveStateValueChange($event)">
            <mat-option *ngFor="let item of liveState; let i = index;"
                        [matTooltip]="item"
                        matTooltipPosition="right"
                        [value]="i+1">
              <span>{{item}}</span>
            </mat-option>
          </mat-select>-->
              <mat-select formControlName="liveState" (valueChange)="liveStateValueChange($event)">
                <mat-option matTooltipPosition="right"
                            [value]="1">
                  <span> {{'membership-form.yes' | translate}}</span>
                </mat-option>
                <mat-option matTooltipPosition="right"
                            [value]="2">
                  <span> {{'membership-form.no' | translate}}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('liveState', 'required')">
                {{'ValidationMessages.required' | translate}}
              </mat-error>
            </mat-form-field>

            <p style="font-size: 13px; margin-top: -7px;" class="text-align-justify">{{'membership-form.live-state-info' | translate }}</p>
          </ng-container>

          <ng-container *ngIf="showdisplacedPlace">
            <mat-form-field appearance="outline">
              <mat-label>{{numberStartsFromLiveState}}.1 {{'membership-form.displaced-place' | translate}}</mat-label>
              <mat-select formControlName="regionId" (valueChange)="regonIdChange($event)">
                <mat-option *ngFor="let item of regions" [value]="item?.id">
                  <span>{{item?.name}}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('regionId', 'required')">
                {{'ValidationMessages.required' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="showPlace">
              <mat-label>{{numberStartsFromLiveState}}.2 {{'membership-form.artsakh-place' | translate}}</mat-label>
              <input matInput autocomplete="off" formControlName="place" />
            </mat-form-field>
          </ng-container>

          <app-address #address (formReady)="registerForm($event, 'currentAddress')"></app-address>

        </div>

        <div class="item">




          <mat-form-field appearance="outline" *ngIf="!showSectorInput">
            <mat-label>
              {{this.coopereationTypeInputNumber}}
              {{'membership-form.sectors' | translate}}
            </mat-label>
            <mat-select formControlName="sectorId" #sectorList (valueChange)="secorValueChange($event)">
              <mat-option *ngFor="let item of sectors"
                          [value]="item?.id">
                {{item?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('sectorId', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="showSectorInput">
            <mat-label>
              {{coopereationTypeInputNumber}}
              {{'membership-form.sectors' | translate}}
            </mat-label>
            <input matInput autocomplete="off"
                   placeholder="{{'membership-form.another-option' | translate}}"
                   formControlName="sectorName" #sector />
            <button matSuffix mat-icon-button type="button">
              <mat-icon (click)="showSectorList()"
                        matTooltip="{{'go-to-list' | translate}}"
                        style="cursor: pointer;color: #001C39"
                        matSuffix>list</mat-icon>
            </button>
            <!--<mat-error *ngIf="hasError('sectorName', 'required')">
          {{'ValidationMessages.required' | translate}}
        </mat-error>-->
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>
              {{this.coopereationTypeInputNumber}}.1
              {{ (this.changeworkPlaceText ? 'membership-form.work-place-2' : 'membership-form.work-place') | translate }}
            </mat-label>
            <input matInput autocomplete="off" formControlName="workPlace" [required]="workPlaceRequired" />
            <mat-error *ngIf="hasError('workPlace', 'required')">
              {{'ValidationMessages.required' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>  {{this.coopereationTypeInputNumber}}.2 {{'membership-form.your-status-there' | translate}}</mat-label>
            <input matInput autocomplete="off" formControlName="state" />
          </mat-form-field>

          <!--<mat-form-field appearance="outline">
        <mat-label> {{this.coopereationTypeInputNumber}} {{'membership-form.cooperationType' | translate}}</mat-label>
        <mat-select formControlName="COmCooperationType" (valueChange)="coopereationTypeValueChange($event)">
          <mat-option *ngFor="let item of cooperationType; let i = index;"
                      [matTooltip]="item"
                      matTooltipPosition="right"
                      [value]="cooperationTypeEnum[i+1]">
            {{item}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('COmCooperationType', 'required')">
          {{'ValidationMessages.required' | translate}}
        </mat-error>
      </mat-form-field>-->

          <p style="font-size:13px" class="text-align-justify"> {{this.coopereationTypeInputNumber + 1}} {{'membership-form.reason' | translate}}</p>
          <mat-form-field appearance="outline">
            <mat-label></mat-label>
            <input matInput autocomplete="off" formControlName="reason" />
          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="!showSupportTypeInput">
            <mat-label>{{this.coopereationTypeInputNumber + 2}} {{'contact-us-form.contribution' | translate}}</mat-label>
            <mat-select formControlName="supportWayIds"
                        #supportWayList
                        (valueChange)="supportTypeValueChange($event)"
                        multiple>
              <mat-option *ngFor="let item of supportWays;"
                          [value]="item?.supportWayId">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showSupportTypeInput" appearance="outline">
            <mat-label>{{this.coopereationTypeInputNumber + 2}} {{'contact-us-form.contribution' | translate}}</mat-label>
            <input placeholder="{{'membership-form.another-option' | translate}}"
                   #supportWayName matInput autocomplete="off" formControlName="supportWay" />
            <button matSuffix mat-icon-button type="button">
              <mat-icon (click)="showsupportWayList()"
                        matTooltip="{{'go-to-list' | translate}}"
                        style="cursor: pointer;color: #001C39"
                        matSuffix>list</mat-icon>
            </button>
          </mat-form-field>

          <ng-container *ngIf="showFileUpload">
            <p style="font-size:14px;" class="text-align-justify">
              {{this.coopereationTypeInputNumber + 3}}
              {{ (this.changeFileUploadText ? 'membership-form.attach-file-2' : 'membership-form.attach-file') | translate }}
            </p>

            <div class="col-12 mb-3 p-0" style="padding-top:10px;">
              <input formControlName="fileInput" hidden type="file" id="file"  multiple (change)="onFileSelected($event)" #fileControl />

              <label for="file" class="fileLabel d-flex align-items-center">
                <mat-icon>
                  attach_file
                </mat-icon>
                <span id="filename">{{'attach-file' | translate}} &hellip;</span>
              </label>
              <span style="font-size:13.5px;" [matTooltip]="getTooltipText()">
                {{'membership-form.choosen-files' | translate}}: {{selectedFiles?.length}}
              </span>
              <p style="font-size:12.5px;">
                {{'extentions' | translate}} .png, .jpg, .jpeg, .svg, .pdf
              </p>
              <p style="font-size:12.5px;">
                {{'file-size' | translate}}
              </p>
            </div>
            <mat-error *ngIf="hasError('fileInput', 'required')">
              <span class="error-message">{{'ValidationMessages.required' | translate}}</span>
            </mat-error>
          </ng-container>
          <!--{{this.coopereationTypeInputNumber + 4}}-->

          <p style="font-size:13px;">
            8 {{'membership-form.comment' | translate}}
          </p>
          <mat-form-field appearance="outline" class="a">
            <mat-label></mat-label>
            <textarea [maxLength]="500"
                      style="max-height:55px;"
                      matInput formControlName="comment"></textarea>
          </mat-form-field>
        </div>

      </div>


      <div class="confirm-text">
        <div>
          <mat-checkbox #confirmCheckbox (change)="onAccept(confirmCheckbox.checked)">
          </mat-checkbox>
        </div>
        <p>
          {{'accept' | translate}}
          <a [href]="this.currentLangUrl + '/charter'">{{'accept-link' | translate}}</a>
          {{'accept-2' | translate}}
          *
        </p>
      </div>

      <div class="confirm-text">
        <div>
          <mat-checkbox #confirmCheckbox1 (change)="onAgree(confirmCheckbox.checked)">
          </mat-checkbox>
        </div>
        <p>
          {{'agree' | translate}}
          <a [href]="this.currentLangUrl + '/privacy-policy'">{{'agree-link' | translate}}</a>
          {{'and' | translate}}
          <a [href]="this.currentLangUrl + '/terms-of-use'">{{ 'terms-of-service-link' | translate }}</a>
          {{'agree-2' | translate}}*

        </p>
      </div>

      <div *ngIf="invalidText" class="invalid-text">{{'selectCheckbox' | translate}}</div>


      <re-captcha 
                  (resolved)="resolved($event)"
                  (error)="recaptchaFailed($event)"
                  size="invisible"
                  errorMode="handled"
                  #recaptcha></re-captcha>

      <div class="btn">
        <button mat-raised-button type="submit" class="btn-primary" [disabled]="this.isLoading">
          {{'btn.membership' | translate}}
        </button>
      </div>



    </form>
  </div>
</div>

