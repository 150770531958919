<ng-container [formGroup]="form" class="form">

  <div class="filteration" *ngIf="filtration; else address">
    <mat-form-field appearance="outline">
      <mat-label> {{ 'address-filtration.country' | translate }}</mat-label>
      <mat-select formControlName="republicId">
        <mat-option *ngFor="let c of republics$ | async" [value]="c.id">
          {{c.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="showInput">
      <mat-label matTooltip="{{'address-filtration.region' | translate}}">{{'address-filtration.region' | translate}}</mat-label>
      <mat-select #region formControlName="regionId">
        <mat-option *ngFor="let r of regions$ | async" [value]="r.id">
          {{r.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!showInput">
      <mat-label>

        {{ 'address-filtration.region' | translate }}
      </mat-label>
      <input matInput autocomplete="off" formControlName="place" />
    </mat-form-field>
  </div>

  <ng-template #address>
    <mat-form-field appearance="outline">
      <mat-label>{{this.inputNumberArrangement}} {{ (this.changeText ? 'membership-form.current-place-2' : 'membership-form.current-place') | translate }}</mat-label>
      <mat-select formControlName="republicId">
        <mat-option *ngFor="let c of republics$ | async" [value]="c.id">
          {{c.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('republicId', 'required') && validation">
        {{'ValidationMessages.required' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="showInput">
      <mat-label>{{this.inputNumberArrangement}}.{{subNumber}} {{'membership-form.region' | translate}}</mat-label>
      <mat-select #region formControlName="regionId">
        <mat-option *ngFor="let r of regions$ | async" [value]="r.id">
          {{r.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('regionId', 'required') && validation">
        {{'ValidationMessages.required' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!showInput">
      <mat-label>
        {{this.inputNumberArrangement}}.{{subNumber+1}}
        {{ (this.showInput ? 'contact-us-form.place' : 'membership-form.place-2') | translate }}
      </mat-label>
      <input matInput autocomplete="off" formControlName="place" />
      <mat-error *ngIf="hasError('place', 'required') && validation">
        {{'ValidationMessages.required' | translate}}
      </mat-error>
    </mat-form-field>
  </ng-template>

</ng-container>


