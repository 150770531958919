<!--
<div style="    padding-left: 400px;">
  <app-sign-in-credentials (emailEmit)="getEmail($event)" ></app-sign-in-credentials>
</div>
-->


<div class="stepper-content">
  <mat-stepper linear #stepper>
    <mat-step  [editable]="isEditable">
      <ng-template matStepLabel>{{"signInCredentials" | translate}}</ng-template>
      <app-sign-in-credentials (emailEmit)="getEmail($event)"></app-sign-in-credentials>
    </mat-step>
    <mat-step  [editable]="isEditable">
      <ng-template matStepLabel>{{"verifyEmail" | translate}}</ng-template>
      <app-email-verify [email]="this.email" *ngIf="showEmailVerify"></app-email-verify>
    </mat-step>
  </mat-stepper>
</div>
