import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../../../material.module";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChangePasswordComponent} from "./change-password.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [
    ChangePasswordComponent
  ]
})
export class ChangePasswordModule{}
