import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BaseHelperComponent } from '../../../../core/helper-components/base.helper-component';
import { Language } from '../../../../web-api-client';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.css']
})
export class ShareButtonsComponent extends BaseHelperComponent implements OnInit {

  @Input() url: any
  @Input() img: any
  @Input() title: any
  @Input() description: any
  applicationName: string = ''

  constructor(
    private meta: Meta
  ) {
    super()
  }

  ngOnInit(): void {
    setTimeout(() => {


      //this.updateMetadata()
    }, 0);
    this.applicationName = this.selectedLanguage == Language.En ? 'Artsakh Union'
      : this.selectedLanguage == Language.Hy ? 'Արցախ Միություն' : 'Союз Арцаха'
  }

  updateMetadata() {
    try {
      // Update Open Graph meta tags
      this.meta.updateTag({ property: 'og:url', content: this.url });
      this.meta.updateTag({ property: 'og:title', content: this.title });
      this.meta.updateTag({ property: 'og:description', content: this.applicationName });
      this.meta.updateTag({ property: 'og:image', content: this.img });
      this.meta.updateTag({ property: 'og:image:alt', content: this.title });

      // Update Twitter meta tags
      this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
      this.meta.updateTag({ name: 'twitter:title', content: this.title });
      this.meta.updateTag({ name: 'twitter:description', content: this.applicationName });
      this.meta.updateTag({ name: 'twitter:image', content: this.img });
      this.meta.updateTag({ name: 'twitter:image:alt', content: this.title });
    } catch (er) {
      let x = er;
    }
  }
}
