import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SignInComponent} from "./sign-in/sign-in.component";
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../../../material.module";
import {TranslateModule} from "@ngx-translate/core";
import { SignInCredentialsComponent } from './sign-in-credentials/sign-in-credentials.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import {ChangePasswordModule} from "../change-password/change-password.module";
import {CodeInputModule} from "angular-code-input";
import { TimerComponent } from './timer/timer.component';
import {AuthService} from "../../../providers/services/auth.service";
import {AuthenticationGuard} from "./authentication.guard";

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  declarations: [
    SignInComponent,
    SignInCredentialsComponent,
    EmailVerifyComponent,
    TimerComponent
  ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
      ChangePasswordModule,
      CodeInputModule.forRoot({
        codeLength: 6,
        isCharsCode: false,
      }),
    ],
  providers:[
    AuthService
  ]
})
export class IdentityModule { }
