import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { FormBaseComponent } from '../../../../core/helper-components/form.base.component';
import { Patterns } from '../../../../providers/validators/validators.pattern';
import {CreateSubscriptionCommand, SubscriptionClient} from '../../../../web-api-client';
import { SubscriptionConfirmedComponent } from '../subscription-confirmed/subscription-confirmed.component';

@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss']
})
export class CreateSubscriptionComponent extends FormBaseComponent implements OnInit {

  @ViewChild(FormGroupDirective) formGroupDirective!: FormGroupDirective;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionConfirmedComponent>,
    private dialog: MatDialog,
    private router: Router,
    private _subscriptionClient: SubscriptionClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: FormBuilder
  ) {
    super(fb)
  }

  showMatDivider: boolean = false
  ngOnInit(): void {
    this.initForm()
    this.showMatDivider = this.data.isDialog ? false : true
  }

  navigateToHome() {
    this.dialogRef.close(true)
    this.router.navigate(['/' + this.currentLangUrl]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateSubscriptionComponent, {
      width: '70%',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true
    });
    this.data.isDialog = false;
    //dialogRef.afterClosed().subscribe((result: any) => {
    //  if (result) {
    //    this.navigateToHome()
    //  } else {
    //    // User may have canceled or closed the dialog (optional handling)
    //  }
    //});
  }

  submit() {
    if (this.form.invalid || this.isLoading) {
      this.validateForm()
      return;
    }

    this.isLoading = true;

    let cmd = <CreateSubscriptionCommand> this.form.value;
    cmd.language = this.selectedLanguage;
    this._subs.add(
      this._subscriptionClient.create(
        this.form.value
      ).pipe().subscribe({
        next: (result: any) => {
          this.isLoading = false;
          if (result) {
            if (this.data.isDialog) {
              this.navigateToHome()
            }
            this.openThanksDialogDialog()
          }
        },
        error: (error: any) => {
          console.log(error)
          this.isLoading = false;
        }
      })
    )
  }

  openThanksDialogDialog() {
    const dialogRef = this.dialog.open(SubscriptionConfirmedComponent, {
      width: '70%',
      maxHeight: '90vh',
      autoFocus: false
    });
    this._subs.add(
      dialogRef.afterClosed()
        .subscribe({
          next: (isAccepted: any) => {
            this.resetform();
            this.formGroupDirective.resetForm();
          },
          error: (error) => {
          }
        })
    )

  }


  private initForm() {
    this.form = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.pattern(Patterns.Email),
      ]],
      phone: ['', Validators.pattern(Patterns.PhonePattern)],
    });
  }

}
