import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../../material.module";
import { NavLanguageComponent } from './nav-language/nav-language.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddressComponent } from './address/address.component';
import { ImageDisplayComponent } from './image-display/image-display.component';
import { LandingHeadingComponent } from './landing-heading/landing-heading.component';
import { ShareButtonsComponent } from './share-buttons/share-buttons.component';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { TermsContextComponent } from './terms-context/terms-context.component';
//import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
//import { ShareIconsModule } from 'ngx-sharebuttons/icons';
const routes: Routes = [
];

@NgModule({
  declarations: [
    NavLanguageComponent,
    AddressComponent,
    ImageDisplayComponent,
    LandingHeadingComponent,
    ShareButtonsComponent,
    CommonHeaderComponent,
    TermsContextComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    //ShareButtonsModule,
    //ShareIconsModule,
  ],
  exports: [
    NavLanguageComponent,
    AddressComponent,
    ImageDisplayComponent,
    LandingHeadingComponent,
    ShareButtonsComponent,
    CommonHeaderComponent,
    TermsContextComponent,
  ]
})
export class SharedModule { }
