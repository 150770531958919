import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseHelperComponent} from "./core/helper-components/base.helper-component";
import {UpsertHelperComponent} from "./core/helper-components/upsert.helper-component";
import {WidgetsModule} from "./ui/widgets/widgets.module";
import {AppInitializeService} from "./providers/services/app-initialize.service";
import {AppInterceptor} from "./providers/interceptors/app.interceptor";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorIntlService} from "./providers/services/mat-paginatorIntl.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ComponentBindingService} from "./providers/services/component-binding.service";
import { NotifierModule } from 'angular-notifier';
import { NOTIFIER_OPTIONS } from './core/constants/notifer.options';
import {MatMenuModule} from "@angular/material/menu";
import {LanguageInterceptor} from "./providers/interceptors/language.interceptor";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DisplayLanguage} from "./core/constants/displayLanguage";
import {PageNotfoundComponent} from "./ui/componenets/page-notfound/page-notfound.component";
import {IdentityModule} from "./ui/modules/identity/identity.module";
import { NgImageSliderModule } from 'ng-image-slider';
import { CreateSubscriptionComponent } from './ui/modules/subscriptions/create-subscription/create-subscription.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppItems } from './core/constants/app.items';
import { ScrollTopComponent } from './ui/componenets/shared/scroll-top/scroll-top.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GtagModule } from 'angular-gtag';

export function appInitialize(appInitializeService: AppInitializeService) {
  return (): Promise<any> => {
    return appInitializeService.Init();
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    BaseHelperComponent,
    UpsertHelperComponent,
    AppComponent,
    PageNotfoundComponent,
    ScrollTopComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    IdentityModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WidgetsModule,
    NotifierModule.withConfig(NOTIFIER_OPTIONS),
    MatMenuModule,
    MatProgressSpinnerModule,
    RecaptchaModule,
    NgImageSliderModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: DisplayLanguage.En,
    }),
    GtagModule.forRoot({ trackingId: 'G-EG6Z1BJRCJ', trackPageviews: true })
  ],
  providers: [
    AppInitializeService,
    ComponentBindingService,
    MatPaginatorIntlService,
    {provide: APP_INITIALIZER, useFactory: appInitialize, deps: [AppInitializeService], multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlService
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: "6Lfan5gpAAAAAHanwfjWgQKPV3yKa_66mJrXeUXM" } as RecaptchaSettings, 
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: { siteKey: "6Lfan5gpAAAAAHanwfjWgQKPV3yKa_66mJrXeUXM" } as RecaptchaSettings, 
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: localStorage.getItem(AppItems.Lang) || DisplayLanguage.En,
    },
    HttpClient
  ],
  bootstrap: [AppComponent],
  entryComponents: [CreateSubscriptionComponent]
})
export class AppModule {
}
