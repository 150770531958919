export const ActivityEn = [
  "Academia",
  "Arts and Culture",
  "Banking and Finance",
  "Business and Entrepreneurship",
  "Civil Service and Government",
  "Communications and Media",
  "Community Development",
  "Consulting",
  "Education and Research",
  "Engineering",
  "Environmental Sciences",
  "Healthcare and Medicine",
  "Human Rights Advocacy",
  "Information Technology and Digital Innovation",
  "International Relations",
  "Law and Legal Services",
  "Marketing and Advertising",
  "Nonprofit and NGO Sector",
  "Philanthropy",
  "Politics and Policy Making",
  "Social Work",
  "Urban Planning and Development"
]
export const ActivityHy = [
  "Ակադեմիա",
  "Առողջապահություն և բժշկություն",
  "Արվեստ և մշակույթ",
  "Բանկային գործ և ֆինանսներ",
  "Բնապահպանություն",
  "Բիզնես և ձեռներեցություն",
  "Իրավաբանական ծառայություններ",
  "Խորհրդատվություն",
  "Կրթություն և հետազոտություն",
  "Հաղորդակցություն և մեդիա",
  "Համայնքային զարգացում",
  "Հանրային ծառայություն և պետակառավարում",
  "Ճարտարագիտություն",
  "Մարդասիրություն",
  "Մարդու իրավունքների պաշտպանություն",
  "Մարքեթինգ և գովազդ",
  "Միջազգային հարաբերություններ",
  "Ոչ առևտրային և հասարակական հատված",
  "Սոցիալական աշխատանք",
  "Տեղեկատվական տեխնոլոգիաներ և թվային նորարարություն",
  "Քաղաքականություն և հանրային ծրագրերի մշակում",
  "Քաղաքային պլանավորում և զարգացում"
]



export const SupportTypesEn = [
  "Financial donation",
  "Participation in advocacy campaigns",
  "Sharing professional skills",
  "Building networks",
  "Participation in awareness raising",
  "Other"
]
export const SupportTypesHy = [
  "Ֆինանսական նվիրատվություն",
  "Շահերի պաշտպանության համար արշավներին մասնակցություն",
  "Մասնագիտական կարողությունների փոխանցում",
  "Կապերի ձևավորում",
  "Տեղեկատվական աշխատանքներին մասնակցություն",
  "Այլ"
]



export const PersonStatusEn = [
  "Natural person",
  "Representative of a legal entity"
]
export const PersonStatusHy = [
  "Ֆիզիկական անձ",
  "Իրավաբանական անձի ներկայացուցիչ"
]
export const PersonStatusRu = [
  "Физическое лицо",
  "Представитель юридического лица"
]


export const CooperationTypeEn = [
  "Membership and support of works",
  "Other support/Partnership without membership"
]
export const CooperationTypeHy = [
  "Անդամակցություն և աջակցություն աշխատանքներին",
  "Այլ աջակցություն/Գործընկերություն՝ առանց անդամակցության"
]
export const CooperationTypeRu = [
  "Членство",
  "Поддерживать"
]


export const LiveStateEn = [
  "I was registered in Artsakh at that time",
  "At that time, I lived in Artsakh, but I was not registered there",
  "I was registered and living outside of Artsakh at the specified time"
]
export const LiveStateHy = [
  "Նշված պահին հաշվառված էի Արցախում",
  "Նշված պահին բնակվում էի Արցախում, բայց հաշվառված չէի այնտեղ",
  "Նշված պահին հաշվառված և բնակվում էի Արցախից դուրս"
]
export const LiveStateRu = [
  "В то время я был прописан в Арцахе.",
  "В то время я жил в Арцахе, но не был там прописан.",
  "На тот момент я не был прописан и не проживал в Арцахе."
]
