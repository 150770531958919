import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../../providers/services/title.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor(
    private titleService: TitleService
  ) {
    this.titleService.setTitleWithTranslateion('news')
  }

  ngOnInit(): void {
  }

}
