import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {UpsertHelperComponent} from "../../../../core/helper-components/upsert.helper-component";
import {IdentityService} from "../../../../providers/services/identity.service";
import {MatDialog} from "@angular/material/dialog";
import {IdentityClient, IdentityResponse, SignInCommand} from "../../../../web-api-client";
import {Router} from "@angular/router";
import {MatStepper} from "@angular/material/stepper";
import {BaseHelperComponent} from "../../../../core/helper-components/base.helper-component";

;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends BaseHelperComponent implements OnInit {

  email! : string;
  showEmailVerify : boolean = false;
  @ViewChild('stepper') stepper: any;


  isLoading = false;
  isEditable = false;

  constructor(
    private formBuilder: FormBuilder,
    private identityClient: IdentityClient,
    private identityService: IdentityService,
    private router : Router,
    private dialog: MatDialog,
  ) {
    super();
  }


  getEmail($event : any){
    this.email = $event;
    this.showEmailVerify = true;
    this.stepper.next();
  }

  ngOnInit(): void {
  }

}

