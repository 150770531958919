import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseHelperComponent } from '../../../../../core/helper-components/base.helper-component';
import { ArticleClient, ArticleContentResponse, ArticleResponse, Language } from '../../../../../web-api-client';
import { Location } from '@angular/common';
import { DomSanitizer, Meta, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../../../../providers/services/file.service';
import { TitleService } from '../../../../../providers/services/title.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent extends BaseHelperComponent implements OnInit {
  id?: number
  article!: ArticleResponse
  content!: ArticleContentResponse
  lang!: Language

  youtubeVideoUrl!: string; 
  youtubeVideoId!: string;
  videoURL!: string
  safeURL: any

  currentUrl!: string

  constructor(
    private route: ActivatedRoute,
    private _articleClient: ArticleClient,
    private _location: Location,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private titleService: TitleService

  ) {
    super()
 
  }

  ngOnInit(): void {
    this.lang = this.selectedLanguage
    this.route.params.subscribe(params =>
      this.id = params['id']
    )
    this.get()
    this.isMobile = window.innerWidth < 768
    this.currentUrl = window.location.href 
  }
  goBack() {
    this._location.back();
  }

  isMobile: boolean = false
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth < 768;
  }

  URL!: any
  getVideoUrl() {
    const videoUrl = `https://www.youtube.com/embed/${this.youtubeVideoId}`;
    this.URL = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl)
  }

  imageObject: Images[] = []
  get() {
    this._subs.add(
      this._articleClient.getDetailsById(
        this.id!,this.selectedLanguage
      ).pipe().subscribe({
        next: (data: ArticleContentResponse) => {

          this.content = data
          this.youtubeVideoUrl = this.content.videoLink!
          this.titleService.setTitle(this.content.title!)

          if (this.youtubeVideoUrl) {
            this.youtubeVideoId = this.extractVideoId(this.youtubeVideoUrl);
            this.getVideoUrl()
          }
      
          this.content?.files?.forEach((filePath: any) => {
            this.imagesUrls(filePath.path)
          });

          console.log(this.content);
        },
        error: (error: any) => {
          console.log(error)
        }
      })
    )
  }

  imageData: SafeUrl | null = null;
  imagesUrls(filePath: string) {
    this.fileService.downloadFile(filePath).subscribe(
      (data: Blob) => {
        const objectURL = URL.createObjectURL(data);
        this.imageData = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.imageObject.push({
          thumbImage: objectURL, // Use the file path as the thumbImage
          image: objectURL // Use the generated URL as the image
        });
      },
      (error: any) => {
        console.error('Error downloading image:', error);
      }
    );
  }

  private extractVideoId(url: string): string {
    const videoIdMatch = url.match(/[?&]v=([^&#]+)/);
    return videoIdMatch && videoIdMatch[1] ? videoIdMatch[1] : '';
  }

}
export class Images {
  thumbImage?: string
  image?: string
  title?: string
}
