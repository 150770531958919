
<div class="thanks-block">
  <img width="100" height="100" src="./assets/images/task_alt.svg" />
  <p>{{ 'contact-us-form.thanks-text' | translate }}</p>
  <p style="font-size:17px;">
    {{ 'contact-us-form.financial-donation-1' | translate }}
    <a [href]="this.reArmeniaUrl" target="_blank">{{ 'contact-us-form.link' | translate }}</a>
    {{ 'contact-us-form.financial-donation-2' | translate }}
  </p>
  <button mat-raised-button mat-dialog-close type="button"
          style="margin-top:10px;"
          class="btn-primary">
    {{ 'btn.return-back' | translate }}
  </button>
</div>
