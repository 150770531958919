import {Injectable} from '@angular/core';
import {AuthClient, RefreshTokenCommand} from "../../web-api-client";
import {AuthService} from "./auth.service";

@Injectable()
export class AppInitializeService {

  constructor(
    private authService: AuthService,
              private authClient: AuthClient) {
  }

  Init() {
    return new Promise<void>((resolve, reject) => {

      if (this.authService.getAccessToken()) {
        // Check if access token has expired
        if (this.authService.isAccessTokenExpired()) {
          // Refresh token
          let cmd = new RefreshTokenCommand();
          cmd.accessToken = this.authService.getAccessToken();
          cmd.refreshToken = this.authService.getRefreshToken();
          this.authClient.refreshToken(cmd).subscribe(
            (newToken) => {
              // Token refreshed successfully, update tokens and restart token timer
              this.authService.setTokens(newToken);
           //   this.authService.startRefreshTokenTimer();
              resolve();
            },
            (error) => {
              // Handle error refreshing token (e.g., redirect to login page)
              //console.error('Error refreshing token:', error);
              this.authService.removeTokens()
              this.authService.navigateToLoginPage()
             // reject();
            }
          );
        } else {
          // Access token is still valid, send heartbeat
          this.authClient.sendHeartbeat().subscribe(
            {
              next: (next) => {
                if (this.authService.getSignIn()) {
                  this.authService.startRefreshTokenTimer();
                }
              }
            }
          );
          resolve();
        }
      } else {
        // No access token found, resolve immediately
        resolve();
      }
    });
  }


}
