import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import jwt_decode from "jwt-decode";
import {Router} from "@angular/router";
import {BehaviorSubject, Subject, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Claims} from "../../core/models/claims.model";
import {IdentityResponse} from "../../web-api-client";

@Injectable({
  providedIn: "root",
})
export class IdentityService {
  private readonly url = "api/Identity";
  loading: boolean = false;
  private refreshTokenTimeout: any;
  timeout$ = new BehaviorSubject<number>(0);
  private loginSubject = new Subject<void>();


  constructor(private http: HttpClient, private router: Router, private injector: Injector) {
  }

  renewTokens() {
    return this.http.get(this.url + `/RenewTokens`, {
      headers: {
        "Authorization": "Bearer " + this.accessToken
      }
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  login(identityResponse : IdentityResponse) {
    this.setAccessToken(identityResponse?.accessToken, false);
    this.loginSubject.next();
  }

  onLogin() {
    return this.loginSubject.asObservable();
  }

/*  public startRefreshTokenTimer() {
    const expires = new Date(this.decodeToken()['exp'] * 1000);
    let timeout = expires.getTime() - Date.now() - (60 * 1000);

    this.refreshTokenTimeout = setTimeout(() => {
      this.renewTokens().subscribe((response: any) => {
        this.setAccessToken(response?.accessToken)
      });
    }, timeout);

  }*/


  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  setAccessToken(token: string | undefined, startRefreshTimer: boolean = true) {
    localStorage.setItem("accessToken", token ?? '');
    if (startRefreshTimer) {
     // this.startRefreshTokenTimer();
    }
  }


  get isSignedIn(): boolean {
    return this.decodeToken() != null && !this.isAccessTokenExpired;
  }

  get claims(): Claims | null {
    const claims = new Claims();
    claims.id = this.decodeToken()["id"];
    claims.userName = this.decodeToken()["username"];
    claims.roles = this.decodeToken()["role"];
    return claims;
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  get isAccessTokenExpired(): boolean {
    if(!this.decodeToken())
      return false;
    return Date.now() >= this.decodeToken()["exp"] * 1000;
  }

  removeAccessToken() {
    localStorage.setItem("accessToken", "");
  }

  isInRole(roles: string[] | string) {
    let actualRole: string = this.decodeToken()["role"] || '';
    let successResults = 0;

    if (typeof roles === "string") {
      roles = [roles];
    }

    for (const role of roles) {
      if (actualRole.toLowerCase() == role.toLowerCase()) {
        successResults++;
      }
    }

    return successResults > 0;

  }

  isInScope(scopes: string[] | string) {
    let actualScopes: string[] | string = this.decodeToken()["scope"] || [];
    let successResults = 0;

    if (typeof actualScopes === "string") {
      actualScopes = [actualScopes];
    }

    if (typeof scopes === "string") {
      scopes = [scopes];
    }

    for (const actualScope of actualScopes) {
      if (scopes.filter(s => s.toLowerCase() == actualScope.toLowerCase()).length > 0) {
        successResults++;
      }
    }

    return successResults > 0;
  }

  private decodeToken() {
    try {
      return jwt_decode<any>(this.accessToken);
    } catch {
      return null;
    }
  }

  navigateToLoginPage() {
    this.router.navigate(["/identity/sign-in"]);
  }

  navigateToHomePage() {
    this.router.navigate(["/home"]);
  }


}
