import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppItems } from './core/constants/app.items';
import { DisplayLanguage } from './core/constants/displayLanguage';
import { BaseHelperComponent } from './core/helper-components/base.helper-component';
import { AuthorizeGuard } from "./providers/guards/authorize.guard";
import {PageNotfoundComponent} from "./ui/componenets/page-notfound/page-notfound.component";
let lang = 'en'
export const routes: Routes = [
  {
    path: ":lang/subscriptions",
    loadChildren: () => import("./ui/modules/admin-subscriptions/admin-subscriptions.module")
      .then((m) => m.AdminSubscriptionsModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/connections",
    loadChildren: () => import("./ui/modules/connections/connections.module")
      .then((m) => m.ConnectionsModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/memberships",
    loadChildren: () => import("./ui/modules/memberships/memberships.module")
      .then((m) => m.MembershipsModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/articles",
    loadChildren: () =>
      import("./ui/modules/article/article.module").then(
        (m) => m.ArticleModule),
     canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/main", loadChildren:
      () => import("./ui/modules/main/main.module")
        .then((m) => m.MainModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/gallery",
    loadChildren: () =>
      import("./ui/modules/gallery/gallery.module").then(
        (m) => m.GalleryModule),
     canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/projects",
    loadChildren: () =>
      import("./ui/modules/project/project.module").then(
        (m) => m.ProjectModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/user-management",
    loadChildren: () =>
      import("./ui/modules/user-management/user-management.module").then(
        (m) => m.UserManagementModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: ":lang/subscription-form",
    loadChildren: () =>
      import("./ui/modules/subscriptions/subscription.module").then(
        (m) => m.SubscriptionModule),  //todo authorize or not
  },
  //{
  //  path: "stay-in-tuch",
  //  component: CreateSubscriptionComponent
  //},
  {
    path: ":lang/contact-us-form",
    loadChildren: () =>
      import("./ui/modules/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule),
  },
  {
    path: ':lang/charter',
    loadChildren: () =>
      import("./ui/modules/statute/statute.module").then(
        (m) => m.StatuteModule),
  },
  {
    path: ':lang/management',
    loadChildren: () =>
      import("./ui/modules/staff/staff.module").then(
        (m) => m.StaffModule),
  },
  {
    path: ':lang/privacy-policy',
    loadChildren: () =>
      import("./ui/modules/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule),
  },
  {
    path: ':lang/terms-of-use',
    loadChildren: () =>
      import("./ui/modules/terms-of-use/terms-of-use.module").then(
        (m) => m.TermsOfUseModule),
  },
  { path: 'page-notfound', component: PageNotfoundComponent},
  {
    path: ':lang',
    loadChildren: () =>
      import("./ui/modules/home/home.module").then(
        (m) => m.HomeModule),
  },
  {
    path: '', redirectTo: !localStorage.getItem(AppItems.Lang)
      ? localStorage.getItem('default-lang')!
      : localStorage.getItem(AppItems.Lang)!, pathMatch: 'full'
  },
  { path: '**', component: PageNotfoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule extends BaseHelperComponent {

}
