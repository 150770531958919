import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL, FileType} from "../../web-api-client";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileService {

  http: HttpClient;
  baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  upload(file: File, fileType : FileType): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType.toString());
    return this.http.post('api/FileBase', formData);
    /*
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    // const headers = { 'Authorization': 'Bearer ' + this.token};

    return this.http.post(`${this.baseUrl}/api/fileBase`, formData, {
      // headers: headers,
      reportProgress: true,
      observe: 'events'
    });*/
  }
  //todo add headers

  public downloadFile(filePath : any): any{
    return this.http.get('api/FileBase/download/'+filePath,  { responseType: 'blob' });
  }

  public downloadFileWithEvents(filePath : any): any{
    let url = `${this.baseUrl}/api/FileBase/download/${filePath}/`;

    return this.http.get(url, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    })
  }
  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement('a');
    element.setAttribute('href', textUrl);
    element.setAttribute('download', filenameForDownload);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
