import { Component } from '@angular/core';
import { TitleService } from '../../../providers/services/title.service';
import { TranslationService } from '../../../providers/services/translation.service';
import { Meta } from '@angular/platform-browser';
import { Language } from '../../../web-api-client';
import { BaseHelperComponent } from '../../../core/helper-components/base.helper-component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseHelperComponent {
  applicationName: string = ''
  url = 'https://artsakhunion.org'
  img = 'https://artsakhunion.org./assets/images/metting.png'

  constructor(
    public translationService: TranslationService,
    private titleService: TitleService,
    private meta: Meta
  ) {
    super()
    this.titleService.setHomeTitle()
    this.applicationName = this.selectedLanguage == Language.En ? 'Artsakh Union'
      : this.selectedLanguage == Language.Hy ? 'Արցախ Միություն' : 'Союз Арцаха'
    //// Update Open Graph meta tags
    //this.meta.updateTag({ property: 'og:url', content: this.url });
    //this.meta.updateTag({ property: 'og:title', content: this.applicationName });
    //this.meta.updateTag({ property: 'og:description', content: this.applicationName });
    //this.meta.updateTag({ property: 'og:image', content: this.img });
    //this.meta.updateTag({ property: 'og:image:alt', content: this.applicationName });

    //// Update Twitter meta tags
    //this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    //this.meta.updateTag({ name: 'twitter:title', content: this.applicationName });
    //this.meta.updateTag({ name: 'twitter:description', content: this.applicationName });
    //this.meta.updateTag({ name: 'twitter:image', content: this.img });
    //this.meta.updateTag({ name: 'twitter:image:alt', content: this.applicationName });

  }

}
