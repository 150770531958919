<div class="container-fluid py-3">
  <div style="padding: 40px 0 20px;">
    <share-buttons theme="circles-dark"
                   [include]="['facebook', 'twitter', 'linkedin', 'telegram','whatsapp', 'email']"
                   [showIcon]="true"
                   [showText]="false"
                   [url]="this.url"
                   [description]="this.title"
                   class="pt-5">
    </share-buttons>
  </div>
</div>
