<div class="thanks-block">
  <img width="100" height="100" src="./assets/images/task_alt.svg" />
  <p>{{ 'subscription.thanks' | translate }}</p>
  <p style="font-size:17px;">{{ 'subscription.financial-text' | translate }}
    <a [href]="reArmeniaUrl" target="_blank" class="blue-color">{{'subscription.financial-donation' | translate}}</a>
    <span>{{'subscription.membership-text' | translate}}</span>
    <a class="blue-color" (click)="navigate('/membership-form')">{{'subscription.membership-donation' | translate}}</a>
  </p>
  <button mat-raised-button mat-dialog-close type="button"
          style="margin-top:10px;"
          class="btn-primary">
    {{ 'btn.return-back' | translate }}
  </button>
</div>
