<div class="main">
  <p><i>{{'landing-page.dear-visitor' | translate}}</i></p>
  <p>
    {{'landing-page.welcome.text-1' | translate}}
  </p>
  <p>
    {{'landing-page.welcome.text-2' | translate}}
  </p>

  <div class="img-and-missin"> 
    <div class="context">
      <!--<div class="inner">
      <div>
        <img src="./assets/images/Group 1.png" />
        <h1> {{'landing-page.vision.title' | translate}}</h1>
      </div>
      <p>
        {{'landing-page.vision.text' | translate}}
      </p>
    </div>
    <mat-divider vertical="true"></mat-divider>-->

      <div class="inner">
        <div>
          <img src="./assets/images/Group 2.png" />
          <h1> {{'landing-page.mission.title' | translate}}</h1>
        </div>
        <p>
          {{'landing-page.mission.text' | translate}}
        </p>
      </div>
    </div>
    <img width="300" height="300" style="margin-top:3%;" src="./assets/images/mission.svg" />
  </div>


  <!--<div class="objectives">
    <p class="title">{{'objectives.title' | translate}}</p>
    <p>{{'objectives.sub-text' | translate}}</p>
    <ng-container *ngFor="let item of texts; let i = index">
      <div>
        <mat-icon>filter_vintage</mat-icon>
        <span>
          {{translateionService.getTranslation('objectives.text-' + (i + 1))}}
        </span>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

  </div>-->

</div>
