import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {concatMap, filter, tap} from "rxjs/operators";
import {BehaviorSubject, of, switchMap} from "rxjs";
import {FormBaseComponent} from '../../../../core/helper-components/form.base.component';
import {
  Language,
  RegionClient,
  RegionResponse,
  RepublicClient,
  RepublicResponse,
  RepublicType
} from '../../../../web-api-client';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends FormBaseComponent implements OnInit, OnDestroy {

  @Input() validation = true;

  @Input() filtration : boolean = false;

  private _regions$ = new BehaviorSubject<RegionResponse[]>([]);
  private _republics$ = new BehaviorSubject<RepublicResponse[]>([]);

  public regions$ = this._regions$.asObservable();
  public republics$ = this._republics$.asObservable();

  @Output() formReady = new EventEmitter();
  @Input() initialData: any;

  lang!: Language;
  selectedBrpublicId?: number

  //for membership/support form
  changeText: boolean = false
  inputNumberArrangement : number = 3

  subNumber: number = 0;

  constructor(
    private _fb: FormBuilder,
    private _regionClient: RegionClient,
    private _republicClient: RepublicClient
  ) {
    super(_fb);
    this.showInput = false;
  }

  ngOnInit() {
    this.form = this._buildFrom();
    this.formReady.emit(this.form);
    this.lang = this.selectedLanguage;
    this._initializeListeners(this.lang);
  }

  showInput!: boolean


  private _initializeListeners = (lang: Language) => {
    this._subs.add(
      this._republicClient.get(lang).pipe(
        tap(republic => {

          this._republics$.next(republic);
        })
      ).subscribe()
    );

    this._subs.add(
      this.form.get('republicId')?.valueChanges.pipe(
        filter(republicId => !!republicId),
        concatMap(republicId =>
          this._republicClient.getById(republicId).pipe(
            switchMap(republic => {
              if (republic.republicType == RepublicType.Homeland) {
                this.showInput = true;
                this.subNumber = 1;
                this.form.get('regionId')?.setValidators(Validators.required);
                this.form.get('regionId')?.updateValueAndValidity()
                this.form.get('regionId')?.markAsUntouched();

                this.form.get('place')?.setValue('', { emitEvent: true })
                this.form.get('place')?.clearValidators()
                this.form.get('place')?.updateValueAndValidity()

                return this._regionClient.getByRepublicId(republicId, this.selectedLanguage);
              } else {
                this.form.get('regionId')?.setValue('', { emitEvent: true })
                this.form.get('regionId')?.clearValidators()
                this.form.get('regionId')?.updateValueAndValidity()


                this.form.get('place')?.setValidators(Validators.required);
                this.form.get('place')?.updateValueAndValidity()
                this.form.get('place')?.markAsUntouched();

                this.showInput = false;
                this.subNumber = 0;
                return of([]);
              }
            })
          )
        ),
        tap(regions => {
          this._regions$.next(regions);
        })
      ).subscribe()
    );
  }

  clearCommunity(){
    //if (this.form.get('communityId')?.value) {
    //  this.form.get('communityId')?.setValue('');
    //  this.form.get('communityId')?.setValidators(Validators.required);
    //}
  }

  private _buildFrom = () => {

    if(this.validation){
      return this._fb.group({
        republicId: ['', Validators.required],
        place: ['', /*Validators.required*/],
        regionId: [0, /*Validators.required*/] //tempcommented for engagement questionary
      });
    }
    else{
      return this._fb.group({
        republicId: [''],
        place: [''],
        regionId: [0] //tempcommented for engagement questionary
      });
    }

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
