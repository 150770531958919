import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBaseComponent} from "../../../core/helper-components/form.base.component";
import {FormBuilder} from "@angular/forms";
import {InformMessagesConstant} from "../../../core/constants/inform-messages.constant";
import {NotifierService} from "angular-notifier";
import {FileService} from "../../../providers/services/file.service";
import {FileType} from "../../../web-api-client";
import {TranslatedMessageService} from "../../../providers/services/translated-message.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent extends FormBaseComponent implements OnInit {

  @Input() fileType! : FileType;
  @Output() refreshFiles : EventEmitter<void> = new EventEmitter<void>();
  selectedFiles : File [] = [];
   fileExtension :  string[] = [];
  constructor(
    private translatedMessages : TranslatedMessageService,
    private fileService: FileService,
    private notifier: NotifierService,
    fb: FormBuilder) {
    super(fb);

  }

  ngOnInit(): void {
    this.initForm();
    this.fileExtension = this.fileType == FileType.Image ? [ "png", "jpg",  "jpeg", "svg"] : ['pdf'];

  }

  onSubmit(){
    if(this.selectedFiles.length < 1){
      this.translatedMessages.notifyErrorMessage('pleaseChooseFile');
      return;
    }




    for (let i = 0; i < this.selectedFiles.length; ++i){
      console.log(this.selectedFiles);
      this.fileService.upload(this.selectedFiles[i], this.fileType).subscribe( _ => {
          if(i == this.selectedFiles.length - 1){
            this.notifier.notify('success', InformMessagesConstant.SucceededAction)
            this.selectedFiles = [];
            this.form.reset()
            this.refreshFiles.emit();
          }
      }
      )
    }

  }



  fileCountValid(){
    //console.log(event)
    return this.selectedFiles.length > 0 && this.selectedFiles.length < 6;
  }

  private validateExtension() {
    for(let i =0; i < this.selectedFiles.length; ++i){
      let ext = this.selectedFiles[i].name.substring(
        this.selectedFiles[i].name.lastIndexOf('.') + 1).toLowerCase();
      console.log(ext)
      if(!this.fileExtension.includes(ext)){
        return false;
      }
    }
    return true;
  }
  delete(file : any){
    this.selectedFiles = this.selectedFiles.filter(x => x != file)
    if(this.selectedFiles.length == 0){
      this.form.reset()
    }
  }


  onFileSelected(event: any): void {

    for(let i = 0; i < event.target.files.length; ++i){
      this.selectedFiles.push(event.target.files[i]);
    }

    if(!this.fileCountValid()){
      this.translatedMessages.notifyErrorMessage('fileMaxCount');
      this.form.reset()
      this.selectedFiles = [];
      return;
    }

    if(!this.validateExtension()){
      this.fileType == FileType.Image
        ? this.translatedMessages.notifyErrorMessage('imageExtension')
        : this.translatedMessages.notifyErrorMessage('documentExtension');
      this.selectedFiles = [];
      this.form.reset();
    }
  }


  private initForm(){
    this.form = this.fb.group({
      files : []
    });
  }

}
