<div>
  <!--<div class="bg-light-blue">
    <div class="container-90">
      <p class="dark-blue info-d">{{'footer.some-info-abt-donation' | translate}}</p>
      <div class="btn">
        <button mat-raised-button type="submit" class="btn-primary">
          {{'btn.donate-now' | translate}}
        </button>
      </div>
    </div>
  </div>-->

  <div class="footer">
    <div class="margin-top">
      <div class="contact container-90">
        <app-logo class="logo-1" [customClass]="'logo-footer'"></app-logo>

        <div class="contact-us">
          <h2 class="title">{{'footer.connect-with-us' | translate}}</h2>
          <div>
            <a href="https://www.facebook.com/profile.php?id=61556278121060" target="_blank">
              <img alt="Facebook" src="./assets/images/fb.svg" />
            </a>
            <a href="https://www.instagram.com/ArtsakhUnion" target="_blank">
              <img alt="Instagram" src="./assets/images/instagram.svg" />
            </a>
            <a href="https://twitter.com/ArtsakhUnion" target="_blank">
              <img alt="X" src="./assets/images/X.svg" />
            </a>
            <a href="https://t.me/ArtsakhUnion" target="_blank">
              <img alt="Telegram" src="./assets/images/telegram.svg" />
            </a>
          </div>
        </div>

        <div class="mt-20">
          <div class="contact-details">
            <p>
              <mat-icon>location_on</mat-icon> 
              <span>{{'footer.yerevan' | translate}}</span>
            </p>

            <p>
              <mat-icon>email</mat-icon>
              <a href="mailto:info@artsakhunion.org?subject=">
                <p style="color:white;"> info@artsakhunion.org</p>
              </a>
            </p>

          </div>
        </div>
        <!--<div class="mailing">
          <h1 class="title">{{'footer.mailing' | translate}}</h1>
          <form [formGroup]="form" (ngSubmit)="submit()" class="form">
            <mat-form-field>
              <mat-label>{{'label.email' | translate}}</mat-label>
              <input matInput autocomplete="off" formControlName="email" />
            </mat-form-field>
            <div class="btn">
              <button mat-raised-button type="submit" class="btn-white">
                {{'btn.subscribe' | translate}}
              </button>
            </div>
          </form>
        </div>-->
      </div>

      <div class="info-privacy container-90">
        <div class="privacy-and-terms-service">
          <a [href]="this.currentLangUrl + '/' + 'privacy-policy'" class="font-size">{{'footer.privacy-policy' | translate}}</a>
          <span style="margin: 0 10px;">
            <mat-divider vertical="true"></mat-divider>
          </span>
          <a [href]="this.currentLangUrl + '/' + 'terms-of-use'" class="font-size">{{'footer.terms-of-service' | translate}}</a>
        </div>
        <div class="font-size rights">
          {{'footer.all-rights-reserved' | translate}}
        </div>
      </div>
    </div>
  </div>

</div>
