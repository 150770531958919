import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormBaseComponent } from '../../../core/helper-components/form.base.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends FormBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb)
  }

  ngOnInit(): void {
    this.initForm()
  }

  submit() {

  }

  private initForm() {
    this.form = this.fb.group({
      email: [''],
    });
  }


}
