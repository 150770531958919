
<div class="flex-center">
<mat-divider *ngIf="(!isNewsPage && showMatDivider) || (isNewsPage && !showMatDivider)" style="margin-bottom:10px;"></mat-divider>

  <div class="context-header">
    <h2 *ngIf="!isNewsPage" class="title">{{translationService.getTranslation(title)}}</h2>
    <h1 *ngIf="isNewsPage" class="title">{{'header.news' | translate}}</h1>
    <button mat-icon-button
            *ngIf="showTitleButton"
            (click)="navigateToHome()"
            class="mt-10"
            matTooltip="{{'btn.close' | translate}}">
      <mat-icon class="dark-blue">close</mat-icon>
    </button>
    <a *ngIf="showButton" class="show-button" (click)="naviateTo(navigationUrl)">
      <span style="">{{translationService.getTranslation(buttonText)}}</span>
      <mat-icon style="vertical-align:sub;padding-top:2px;">navigate_next</mat-icon>
    </a>
  </div>
</div>
