import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NotifierService} from "angular-notifier";
import {DisplayLanguage} from "../../core/constants/displayLanguage";
import {AppItems} from "../../core/constants/app.items";

@Injectable({
  providedIn: 'root'
})
export  class TranslatedMessageService{

  constructor(
    public notifier: NotifierService,
    public translateService: TranslateService) {
    this.translateService.setDefaultLang(DisplayLanguage.En);
    this.translateService.use(localStorage.getItem(AppItems.Lang) || DisplayLanguage.En);
  }


  notifySuccessMessage(key: string){
    this.translateService.get(key)
      .subscribe((message) => {
        this.notifier.notify('success', message);
      })
  }

  notifyErrorMessage(key: string){
    this.translateService.get(key)
      .subscribe((message) => {
        this.notifier.notify('error', message);
      })
  }

  successMessage(){
    this.notifySuccessMessage("successfulAction");
  }

  errorMessage(){
    this.notifyErrorMessage("actionFailed")
  }
}
