import { Component, OnInit } from '@angular/core';
import { BaseHelperComponent } from '../../../../core/helper-components/base.helper-component';

@Component({
  selector: 'app-thanks-dialog',
  templateUrl: './thanks-dialog.component.html',
  styleUrls: ['./thanks-dialog.component.scss']
})
export class ThanksDialogComponent extends BaseHelperComponent implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
