<div class="containera">
  <div class="bg-img" alt="Background Image"></div>
  <div class="content">
    <h1>{{'main-page.img-text' | translate}}</h1>
    <div class="btn">
      <button (click)="navigate()" mat-button type="submit" class="btn-orange">
        {{'header.join-us' | translate}}
      </button>
    </div>

  </div>
</div>

<div class="text-div">
  <p class="text blue">
    {{'main-page.under-welcome' | translate}}
  </p>
</div>
