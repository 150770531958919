import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DisplayLanguage} from "../../core/constants/displayLanguage";

@Injectable()
export class LanguageInterceptor implements  HttpInterceptor{

  languages = [DisplayLanguage.En, DisplayLanguage.Hy, DisplayLanguage.Ru]
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let lang = localStorage.getItem("lang") || DisplayLanguage.En;

    if(!this.languages.includes(lang)){
      lang = DisplayLanguage.En;
    }

    req = req.clone({
      setHeaders:{
        "Accept-Language": lang
      }
    })
    return next.handle(req);
  }

}
