import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material.module";
import {TranslateModule} from "@ngx-translate/core";
import {PagingComponent} from "./paging.component";
import {MAT_PAGINATOR_DEFAULT_OPTIONS} from "@angular/material/paginator";

@NgModule({
  declarations: [
    PagingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [
    PagingComponent
  ],
  providers:[
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {pageSize: 10, pageSizeOptions: [5, 10, 25, 100]} }
  ]
})
export class PagingModule{}
