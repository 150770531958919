import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseHelperComponent } from '../../../../core/helper-components/base.helper-component';
import { DialogHeaderComponent } from '../../../widgets/dialog-header/dialog-header.component';

@Component({
  selector: 'app-subscription-confirmed',
  templateUrl: './subscription-confirmed.component.html',
  styleUrls: ['./subscription-confirmed.component.scss']
})
export class SubscriptionConfirmedComponent extends BaseHelperComponent implements OnInit {

  constructor(private router: Router,
    public dialogRef: MatDialogRef<DialogHeaderComponent>,  ) {
    super()
  }

  ngOnInit(): void {
  }

  navigate(link: string) {
    const url = this.currentLangUrl + link;
    this.router.navigate([url]);
    this.dialogRef.close(true)
  }

}
