import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TitleService } from '../../../../providers/services/title.service';
import { CreateSubscriptionComponent } from '../create-subscription/create-subscription.component';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.css']
})
export class SubscriptionDialogComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private titleService: TitleService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitleWithTranslateion('stayInTouch')
    this.dialog.open(CreateSubscriptionComponent, {
      data: { isDialog: true },
      width: '70%',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true
    });
  }

}
