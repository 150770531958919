<div [ngClass]="this.data.isDialog ? 'dialog-style' : ''">
  <app-landing-heading [title]="'subscribe-to-updates'"
                       [showTitleButton]="this.data.isDialog"
                       [showMatDivider]="this.showMatDivider"></app-landing-heading>
  <div class="container container-90 margin">
    <div class="text">
      <div class="img-container">
        <img alt="{{'alt.email' | translate}}" class="img" src="../../../../../assets/images/email.png" />
      </div>
    </div>
    <div class="container-form">
      <p class="subscription-tiitle text-align-justify">{{'subscription.title' | translate}}</p>
      <form [formGroup]="form" (ngSubmit)="submit()" class="form">
        <mat-form-field appearance="outline">
          <mat-label>1. {{'subscription.name' | translate}}</mat-label>
          <input matInput autocomplete="off" formControlName="firstname" required />
          <mat-error *ngIf="hasError('firstname', 'required')">
            {{'ValidationMessages.required' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>2. {{'subscription.surname' | translate}}</mat-label>
          <input matInput autocomplete="off" formControlName="lastname" required />
          <mat-error *ngIf="hasError('lastname', 'required')">
            {{'ValidationMessages.required' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>3. {{'subscription.email' | translate}}</mat-label>
          <input matInput autocomplete="off" formControlName="email" />
          <mat-error *ngIf="hasError('email', 'required')">
            {{'ValidationMessages.required' | translate}}
          </mat-error>
          <mat-error *ngIf="hasError('email', 'pattern')">
            {{'ValidationMessages.email' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>4. {{'subscription.phone' | translate}}</mat-label>
          <input matInput autocomplete="off" formControlName="phone" maxlength="20" />
          <mat-error *ngIf="hasError('phone', 'pattern')">
            {{'ValidationMessages.phone-pattern' | translate}}
          </mat-error>
        </mat-form-field>

        <div class="btn">
          <!--<button *ngIf="this.data.isDialog"
                  mat-raised-button
                  (click)="navigateToHome()"
                  type="button"
                  class="btn-primary-blue-solid">
            {{'cancel' | translate}}
          </button>-->
          <button mat-raised-button type="submit" class="btn-primary" [disabled]="this.isLoading">
            {{'btn.submit' | translate}}
          </button>
        </div>
      </form>
    </div>

  </div>
</div>
