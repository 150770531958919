<div class="bg-light-blue">
  <div class="container-90" style="height:70px;">
    <h1 class="title dark-blue">{{'objectives.title' | translate}}</h1>
  </div>
</div>


<div class="img-and-missin container-90">
  <div class="context">
    <div class="inner">
      <p class="mission-text text-align-justify">
        {{'landing-page.mission.text' | translate}}
      </p>
    </div>
  </div>
  <img class="main-img-content"  src="./assets/images/DSC_7282.JPG" />
</div>

<div class="container-90">
  <div class="objectives">
    <p class="sub-title">{{'objectives.sub-text' | translate}}</p>
    <ng-container *ngFor="let item of texts; let i = index">
      <div style="margin-top:20px;">
        <mat-icon>filter_vintage</mat-icon>
        <span class="text-align-justify">
          {{translateionService.getTranslation('objectives.text-' + (i + 1))}}
        </span>
      </div>
      <!--<mat-divider></mat-divider>-->
    </ng-container>
  </div>
</div>
