import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from "@angular/core";
import { AppItems } from "../../core/constants/app.items";

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {

  lang!: string
  item!: any

  itemsPerPageLabel!: string
  nextPageLabel!: string
  previousPageLabel!: string
  firstPageLabel !: string
  lastPageLabel !: string

  constructor() {
    super()
    this.lang = localStorage.getItem(AppItems.Lang)!
    this.item = this.mappedLang[this.lang]

    this.itemsPerPageLabel = this.item.itemsPerPageLabel
    this.nextPageLabel = this.item.nextPageLabel
    this.previousPageLabel = this.item.previousPageLabel
    this.firstPageLabel = this.item.firstPageLabel
    this.lastPageLabel = this.item.lastPageLabel

  }

  mappedLang: Record<string, any>  = {
    ['hy']: {
      itemsPerPageLabel: 'Քանակը էջում',
      previousPageLabel: 'Նախորդ էջ',
      firstPageLabel: 'Առաջին Էջ',
      lastPageLabel: 'Վերջին Էջ'
    },
    ['en']: {
      itemsPerPageLabel: 'Items per page',
      previousPageLabel: 'Previous Page',
      firstPageLabel: 'First Page',
      lastPageLabel: 'Last Page'
    },
    ['ru']: {
      itemsPerPageLabel: 'Количество на странице',
      previousPageLabel: 'Предыдущая страница',
      firstPageLabel: 'Первая страница',
      lastPageLabel: 'Последняя страница'
    }
  }

  getRangeLabel = function (page: number, pageSize: number, length: number) {

    const lang = localStorage.getItem(AppItems.Lang)!; 

    const translations: Record<string, any> = {
      ['hy']: { noData: 'Չկա տվյալ', total: 'Ընդամենը' },
      ['en']: { noData: 'No Data', total: 'Total' },    
      ['ru']: { noData: 'Нет данных', total: 'Общий' }, 
    };

    const translation = translations[lang] || translations['en'];

    if (length === 0 || pageSize === 0) {
      return translation['noData'];
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return startIndex + 1 + ' - ' + endIndex + ', ' + translation['total'] + ' ' + length;
  };

}
