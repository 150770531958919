import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css']
})
export class CommonHeaderComponent implements OnInit {
  @Input() title: string = ''
  constructor(
    private _location: Location
  ) { }

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
  }
}
