import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { RecaptchaComponent } from 'ng-recaptcha';
import { catchError, EMPTY, finalize, from, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import {
  CooperationTypeEn, CooperationTypeHy,
  CooperationTypeRu, LiveStateEn, LiveStateHy,
  LiveStateRu, PersonStatusEn,
  PersonStatusHy, PersonStatusRu,
  SupportTypesEn, SupportTypesHy
} from '../../../../core/constants/strings-array.constants';
import { FormBaseComponent } from '../../../../core/helper-components/form.base.component';
import { FileService } from '../../../../providers/services/file.service';
import { TitleService } from '../../../../providers/services/title.service';
import { TrackLanguageChangeUrlService } from '../../../../providers/services/track-language-change-url.service';
import { TranslationService } from '../../../../providers/services/translation.service';
import { Patterns } from '../../../../providers/validators/validators.pattern';
import { yearValidator } from '../../../../providers/validators/year.validator';
import {
  // CooperationType,
  CreateMembershipCommand,
  FileType, Language, LiveState,
  MembershipClient, PersonStatus,
  RegionClient,
  RegionResponse,
  SectorResponse, SectorsClient, SupportType, SupportWayResponse, SupportWaysClient
} from '../../../../web-api-client';
import { AddressComponent } from '../../../componenets/shared/address/address.component';
import { ThanksDialogComponent } from '../../home/thanks-dialog/thanks-dialog.component';


@Component({
  selector: 'app-create-membership',
  templateUrl: './create-membership.component.html',
  styleUrls: ['./create-membership.component.scss']
})
export class CreateMembershipComponent extends FormBaseComponent implements OnInit, OnDestroy {

  lang?: Language

  personStatusEnum: string[] = Object.values(PersonStatus)
    .filter(value => typeof value === 'number')
    .map(value => value.toString());

  liveStateEnum: string[] = Object.values(LiveState)
    .filter(value => typeof value === 'number')
    .map(value => value.toString());

  supportTypeEnum: string[] = Object.values(SupportType)
    .filter(value => typeof value === 'number')
    .map(value => value.toString());

  languageStatusMap: Record<Language, any> = {
    [Language.En]: { personStatus: PersonStatusEn, cooperationType: CooperationTypeEn, liveState: LiveStateEn, supportType: SupportTypesEn },
    [Language.Hy]: { personStatus: PersonStatusHy, cooperationType: CooperationTypeHy, liveState: LiveStateHy, supportType: SupportTypesHy },
    [Language.Ru]: { personStatus: PersonStatusRu, cooperationType: CooperationTypeRu, liveState: LiveStateRu, supportType: SupportTypesEn },
    0: undefined
  };

  personStatus: string[] = this.languageStatusMap[this.selectedLanguage]?.personStatus;
  cooperationType: string[] = this.languageStatusMap[this.selectedLanguage]?.cooperationType;
  liveState: string[] = this.languageStatusMap[this.selectedLanguage]?.liveState;
  supportType: string[] = this.languageStatusMap[this.selectedLanguage]?.supportType;

  fileType = FileType;
  sectors!: SectorResponse[]
  #onDestroy = new Subject<void>();
  todayDate: Date = new Date();

  @ViewChild('address') address!: AddressComponent;
  @ViewChild(FormGroupDirective) formGroupDirective!: FormGroupDirective;
  @ViewChild('recaptcha') recaptcha!: RecaptchaComponent;

  constructor(
    private _sectorClient: SectorsClient,
    private translationService: TranslationService,
    private notifier: NotifierService,
    private _regionClient: RegionClient,
    private fileService: FileService,
    private _membershipClient: MembershipClient,
    private dialog: MatDialog,
    fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private trackLanguageChangeUrl: TrackLanguageChangeUrlService,
    private _supportwaysClient: SupportWaysClient,
    private titleService: TitleService
  ) {
    super(fb)


    setTimeout(() => {
      (window as any).form = this.form;
    }, 200);

    this.titleService.setTitleWithTranslateion('membership')
  }

  ngOnInit(): void {
    RecaptchaComponent.prototype.ngOnDestroy = function () { };
    this.lang = this.selectedLanguage
    this.generateYears()
    this.initForm()
    this.getSectors()
    this.getSupportWays()
    this.getArtsakhRegions()

    this.route.params.subscribe(value => {
      this.trackLanguageChangeUrl.setLanguageUrl('', value['lang'])
    });

  }


  recaptchaFailed($events: any) {
    //this.disabled = false;
    console.log($events);
  }

  navigate(url: string) {
    this.router.navigate(['/' + this.currentLangUrl + url]);
  }

  currentYear!: any
  years: number[] = [];
  filteredYears: number[] = [];
  showedYears: number[] = []

  generateYears() {
    this.currentYear = new Date().getFullYear();
    const startYear = 1920;
    for (let year = this.currentYear; year >= startYear; year--) {
      this.years.push(year);
    }
    this.filteredYears = this.years;
    this.showedYears = this.years;
  }

  filterYears(event: any) {
    if (this.form.get('birthYear')?.value.length == 4) {
      this.showedYears = []
    }
    else {
      this.showedYears = this.filteredYears
      this.showedYears = this.years.filter(year => year.toString().includes(this.form.get('birthYear')?.value));
    }
  }

  openTnaksDialogDialog() {
    const dialogRef = this.dialog.open(ThanksDialogComponent, {
      width: '70%',
      maxHeight: '90vh',
      autoFocus: false
    });
    this._subs.add(
      dialogRef.afterClosed()
        .subscribe({
          next: (isAccepted: any) => {
            this.resetform();
            this.formGroupDirective.resetForm();
            window.location.reload()
          },
          error: (error) => {
          }
        })
    )

  }
  invalidText: boolean = false
  submit() {

    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.get(controlName);

      if (control && !control.valid) {
        console.log(`Control '${controlName}' is not valid.`);
      } else {
        console.log(`Control '${controlName}' is valid.`);
      }
    });

    console.log(this.form.value);
    console.log("IS FORM INVALID - " + this.form.invalid);

    if (this.form.invalid || this.isLoading) {
      if (!this.accept || !this.agree) {
        this.invalidText = true
      }
      this.validateForm()
      //this.recaptcha.reset()
      return;
    }

    if (!this.accept || !this.agree) {
      this.invalidText = true
     // this.recaptcha.reset()
      return;
    }
    this.recaptcha.execute();
  }

  resolved(token: string) {

    if (token != null) {
      console.log(token);
      this.isLoading = true;
      this.submitted = true;

      if (this.selectedFiles.length > 0) {
        this.uploadFiles(token)
      }
      else {
        this.createMembership(token)
      }
    }
    else {
      console.log("____________TOKEN IS NULL__________");
    }
  }

  createMembership(captchaToken: string) {
    let cmd = <CreateMembershipCommand>Object.assign({})

    cmd.status = this.form.get('status')?.value
    cmd.firstname = this.form.get('firstname')?.value
    cmd.lastname = this.form.get('lastname')?.value
    cmd.email = this.form.get('email')?.value
    cmd.phone = this.form.get('phone')?.value
    cmd.birthYear = this.form.get('birthYear')?.value

    if (!this.form.get('liveState')?.value) {
      this.form.get('liveState')?.setValue(0)
    }
    cmd.liveState = this.form.get('liveState')?.value

    if (!this.form.get('regionId')?.value) {
      this.form.get('regionId')?.setValue(0)
    }
    cmd.regionId = this.form.get('regionId')?.value
    cmd.place = this.form.get('place')?.value
    if (!this.form.get('currentAddress.regionId')?.value) {
      this.form.get('currentAddress.regionId')?.setValue(0)
    }
    cmd.currentAddress = this.form.get('currentAddress')?.value

    if (!this.form.get('sectorId')?.value && this.form.get('sectorName')?.value) {
      this.form.get('sectorId')?.setValue(this.sectors.find(x => x.dataType == 1)?.id)
    }
    if (this.form.get('sectorId')?.value && this.form.get('sectorName')?.value) {
      this.form.get('sectorId')?.setValue(0)
    }

    cmd.sectorId = this.form.get('sectorId')?.value
    cmd.sectorName = this.form.get('sectorName')?.value

    console.log(this.form.get('supportWay')?.value);
    if (!this.form.get('supportWay')?.value && this.showSupportTypeInput) {
      cmd.supportWayIds = [this.supportWays.find(x => x.dataType == 1)?.supportWayId!]
    } else {
      cmd.supportWayIds = this.supportIds
    }

    cmd.supportWay = this.form.get('supportWay')?.value
    cmd.workPlace = this.form.get('workPlace')?.value
    cmd.state = this.form.get('state')?.value
    cmd.comment = this.form.get('comment')?.value
    cmd.fileIds = this.form.get('fileIds')?.value
    cmd.accept = this.accept
    cmd.agree = this.agree
    cmd.language = this.selectedLanguage;

    console.log(cmd);
    console.log("_______TOKEN___________");
    console.log(captchaToken);
    this._subs.add(
      this._membershipClient.create(
        captchaToken, cmd
      ).pipe().subscribe({
        next: () => {
          this.submitted = false;
          this.openTnaksDialogDialog()
          this.isLoading = false;
          this.recaptcha.reset();
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error)
          this.notifier.notify('error', this.translationService.getTranslation('ValidationMessages.something-went-wrong'))
          this.recaptcha.reset();
        }
      })
    )
  }

  private getSectors() {
    this.isLoading = true;

    this._sectorClient.get(
      this.lang
    )
      .pipe(
        tap(result => {
          this.sectors = result
          console.log(this.sectors);
        }),
        catchError(error => {

          console.log(error);

          this.sectors = []

          this.isLoading = false;

          return EMPTY;
        }),
        finalize(() => this.isLoading = false),
        takeUntil(this.#onDestroy)
      ).subscribe();
  }

  supportWays: SupportWayResponse[] = []
  private getSupportWays() {
    this.isLoading = true;

    this._supportwaysClient.get(
      this.lang
    )
      .pipe(
        tap(result => {
          this.supportWays = result
          console.log(this.supportWays);
        }),
        catchError(error => {

          console.log(error);

          this.supportWays = []

          this.isLoading = false;

          return EMPTY;
        }),
        finalize(() => this.isLoading = false),
        takeUntil(this.#onDestroy)
      ).subscribe();
  }

  regions: RegionResponse[] = []
  private getArtsakhRegions() {
    this.isLoading = true;

    this._regionClient.getArtsakhRegions(
      this.lang
    )
      .pipe(
        tap(result => {
          this.regions = result
          console.log(this.regions);
        }),
        catchError(error => {

          console.log(error);

          this.regions = []

          this.isLoading = false;

          return EMPTY;
        }),
        finalize(() => this.isLoading = false),
        takeUntil(this.#onDestroy)
      ).subscribe();
  }

  public registerForm = ($event: AbstractControl, name: string) => {
    this.form.registerControl(name, $event);
  }

  accept: boolean = false
  agree: boolean = false

  onAccept(checked: boolean): void {
    this.accept = !this.accept;
    this.handleInvalidCheckbox()
  }

  onAgree(checked: boolean): void {
    this.agree = !this.agree;
    this.handleInvalidCheckbox()
  }

  handleInvalidCheckbox() {
    if (this.accept && this.agree) {
      this.invalidText = false
    }
    else {
      this.invalidText = true
    }
  }

  selectedFiles: File[] = [];
  onFileSelected(event: any): void {
    this.selectedFiles = [];
    console.log(event.target.files);

    for (let i = 0; i < event.target.files.length; ++i) {
      const file = event.target.files[i];
      const allowedExtensions = ['.png', '.jpg', '.jpeg', '.svg', '.pdf'];
      const extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      const maxSize = 5 * 1024 * 1024;

      if (allowedExtensions.includes(extension) && file.size <= maxSize) {
        this.selectedFiles.push(file);
      } else {
        if (!allowedExtensions.includes(extension)) {
          this.notifier.notify('error', file.name + this.translationService.getTranslation('ValidationMessages.invalid-file-extention'));
        } else {
          this.notifier.notify('error', file.name + this.translationService.getTranslation('ValidationMessages.file-size'));
        }
      }
    }
  }

  getTooltipText(): string {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      return this.selectedFiles.map(file => file.name).join(', ');
    } else {
      return '';
    }
  }

  fileIds: number[] = []
  isUploading: boolean = false

  uploadFiles(captchaToken: string): void {
    const uploadCompletions = from(this.selectedFiles).pipe(
      map(file => this.fileService.upload(file, 2))
    );

    this.isUploading = true;
    uploadCompletions.pipe(
      switchMap(uploadObservable => uploadObservable),
      map(fileId => {
        this.fileIds.push(fileId);
        return fileId;
      })
    ).subscribe({
      next: () => {
        this.form.get('fileIds')?.setValue(this.fileIds);
        this.isUploading = false;
        this.createMembership(captchaToken)
      },
      error: err => {
        this.isUploading = false;
      }
    });
  }

  hideLiveStateData: boolean = false
  changeworkPlaceText: boolean = false
  showdisplacedPlace: boolean = false

  //for input arrangement
  numberStartsFromLiveState: number = 0
  coopereationTypeInputNumber: number = 4

  stateValueChange(value: any) {
    value == 2 ? this.showFileUpload = true : this.showFileUpload = false
    this.handleLiveStateAndDisplacedPlaceInputs(value)
    this.handleWorkPlaceInput(value)
    this.handleFileUpload(value)
  }

  workPlaceRequired: boolean = true
  handleWorkPlaceInput(value: any) {
    if (value == 1) {
      this.address.changeText = false

      this.changeworkPlaceText = false
      this.form.get('workPlace')?.setValue('', { emitEvent: true })
      this.form.get('workPlace')?.clearValidators()
      this.form.get('workPlace')?.updateValueAndValidity()
      this.workPlaceRequired = false
    }
    else {
      this.address.changeText = true

      this.changeworkPlaceText = true
      this.form.get('workPlace')?.setValidators(Validators.required);
      this.form.get('workPlace')?.updateValueAndValidity();
      this.form.get('workPlace')?.markAsUntouched();
      this.workPlaceRequired = true
    }
  }

  handleLiveStateAndDisplacedPlaceInputs(value: any) {
    if (value == 1) {
      this.hideLiveStateData = true

      this.form.get('liveState')?.setValue('', { emitEvent: true })
      this.form.get('liveState')?.setValidators(Validators.required);
      this.form.get('liveState')?.updateValueAndValidity()

      this.form.get('regionId')?.setValue(0, { emitEvent: true })
      this.form.get('regionId')?.setValidators(Validators.required);
      this.form.get('regionId')?.updateValueAndValidity()

      this.numberStartsFromLiveState = 3
      this.address.inputNumberArrangement = 4
      this.coopereationTypeInputNumber = this.address.inputNumberArrangement + 1
      this.inputArrangementAfterCooperation = this.coopereationTypeInputNumber + 1
    }
    else {
      this.hideLiveStateData = false
      this.showdisplacedPlace = false;


      this.form.get('liveState')?.setValue('', { emitEvent: true })
      this.form.get('liveState')?.clearValidators();
      this.form.get('liveState')?.updateValueAndValidity();
      this.form.get('liveState')?.markAsUntouched();


      this.form.get('regionId')?.setValue(0, { emitEvent: true })
      this.form.get('regionId')?.clearValidators();
      this.form.get('regionId')?.updateValueAndValidity();
      this.form.get('regionId')?.markAsUntouched();

      this.address.inputNumberArrangement = 3
      this.coopereationTypeInputNumber = this.address.inputNumberArrangement + 1
      this.inputArrangementAfterCooperation = this.coopereationTypeInputNumber + 1
    }
  }

  liveStateValueChange(value: any) {
    if (value == 1) {
      this.showdisplacedPlace = true;

      this.form.get('regionId')?.setValue(0, { emitEvent: true });
      this.form.get('regionId')?.setValidators(Validators.required);
      this.form.get('regionId')?.updateValueAndValidity();

    }
    else {
      this.showdisplacedPlace = false;

      this.form.get('regionId')?.setValue(0, { emitEvent: true });
      this.form.get('regionId')?.clearValidators();
      this.form.get('regionId')?.updateValueAndValidity();
      this.form.get('regionId')?.markAsUntouched();
    }
  }


  showFileUpload: boolean = false;
  changeFileUploadText: boolean = false;

  handleFileUpload(statusValue: any) {
    statusValue == 2 ? this.changeFileUploadText = true : this.changeFileUploadText = false

    if (statusValue == 1) {
      this.form.get('fileInput')?.setValue('', { emitEvent: true });
      this.form.get('fileInput')?.clearValidators();
      this.form.get('fileInput')?.updateValueAndValidity();
    }
    else {
      this.form.get('fileInput')?.setValidators(Validators.required);
      this.form.get('fileInput')?.setValue('', { emitEvent: true });
      this.form.get('fileInput')?.updateValueAndValidity();
      this.form.get('fileInput')?.markAsUntouched();
    }
  }

  isSupport: boolean = false
  inputArrangementAfterCooperation: number = this.coopereationTypeInputNumber + 2
  client: any

  @ViewChild('sector', { read: ElementRef }) sectorInput!: ElementRef;
  showSectorInput: boolean = false
  secorValueChange(sectorId: any) {

    const selectedSector = this.sectors.find(sector => sector.id === sectorId);
    console.log(selectedSector);

    // const lastSector = this.sectors[this.sectors.length - 1];

    if (selectedSector && selectedSector.dataType == 1) {
      this.showSectorInput = true


      this.form.get('secorId')?.setValue('', { emitEvent: true });
      this.form.get('secorId')?.clearValidators();
      this.form.get('secorId')?.updateValueAndValidity();


      this.form.get('sectorName')?.setValue('', { emitEvent: true });
      //this.form.get('sectorName')?.setValidators(Validators.required);
      this.form.get('sectorName')?.updateValueAndValidity();
      this.form.get('sectorName')?.markAsUntouched();

      setTimeout(() => {
        this.sectorInput.nativeElement.focus();
      }, 0);
    }
    else {
      this.showSectorInput = false

      this.form.get('sectorName')?.setValue('', { emitEvent: true });
      // this.form.get('sectorName')?.clearValidators();
      this.form.get('sectorName')?.updateValueAndValidity();

      this.form.get('sectorId')?.setValidators(Validators.required);
      this.form.get('sectorId')?.updateValueAndValidity();
      this.form.get('sectorId')?.markAsUntouched();
    }
  }

  @ViewChild('sectorList', { static: false }) sector!: MatSelect;
  showSectorList() {
    this.showSectorInput = false

    this.form.get('sectorName')?.setValue('', { emitEvent: true });
    this.form.get('sectorName')?.clearValidators();
    this.form.get('sectorName')?.updateValueAndValidity();

    this.form.get('sectorId')?.setValidators(Validators.required);
    this.form.get('sectorId')?.setValue('', { emitEvent: true });
    this.form.get('sectorId')?.updateValueAndValidity();
    this.form.get('sectorId')?.markAsUntouched();

    setTimeout(() => {
      this.sector.open();
      console.log(this.sector);
    }, 0);


  }

  onYearInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    const year = parseInt(inputValue, 10);
    if (!isNaN(year) && this.years.includes(year)) {
      this.form.get('birthYear')?.setValue(year);
    }
  }


  @ViewChild('supportWayName', { read: ElementRef }) supportInput!: ElementRef;
  showSupportTypeInput: boolean = false
  supportIds: number[] = []
  supportTypeValueChange(event: number[]) {
    console.log(event);
    this.supportIds = []
    if (event.length == 1 && event.some(x => this.supportWays.find(s => s.supportWayId == x)?.dataType == 1)) {
      this.form.get('supportWayIds')?.setValue('', { emitEvent: true });
      this.form.get('supportWayIds')?.updateValueAndValidity();
      this.showSupportTypeInput = true
      this.supportIds = []
      setTimeout(() => {
        this.supportInput.nativeElement.value = null;
        this.supportInput.nativeElement.focus();
      }, 0);
    }
    else {
      this.supportIds = event
    }
  }

  @ViewChild('supportWayList', { static: false }) supportWayList!: MatSelect;
  showsupportWayList() {
    this.showSupportTypeInput = false

    this.form.get('supportWay')?.setValue('', { emitEvent: true });
    this.form.get('supportWay')?.clearValidators();
    this.form.get('supportWay')?.updateValueAndValidity();

    this.form.get('supportWayIds')?.setValue('', { emitEvent: true });
    this.form.get('supportWayIds')?.updateValueAndValidity();
    this.form.get('supportWayIds')?.markAsUntouched();

    setTimeout(() => {
      this.supportWayList.open();
      console.log(this.sector);
    }, 0);
  }

  showPlace: boolean = false
  regonIdChange(event: number) {

    if (event == 12) {
      this.showPlace = false
      this.form.get('place')?.setValue('', { emitEvent: true })
      this.form.get('place')?.updateValueAndValidity();
    }
    else {
      this.showPlace = true
    }
  }

  private initForm() {
    this.form = this.fb.group({
      status: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.pattern(Patterns.Email),
      ]],
      phone: ['', [Validators.required, Validators.pattern(Patterns.PhonePattern)]],
      birthYear: ['', [Validators.required, yearValidator(this.currentYear)]],
      liveState: ['', [Validators.required]],
      regionId: [0, Validators.required],
      place: [''],
      sectorId: ['', [Validators.required]],
      sectorName: [''],
      workPlace: ['', [Validators.required]],
      state: [''],
      comment: [''],
      supportWayIds: [''],
      supportWay: [''],
      reason: [''],
      fileIds: [],
      fileInput: [[Validators.required]],
     // recaptcha: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.#onDestroy.next();
    this.#onDestroy.complete();
  }

}
