import { Component, Input, OnInit } from '@angular/core';
import { AppItems } from '../../../../core/constants/app.items';
import { DisplayLanguage } from '../../../../core/constants/displayLanguage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-language',
  templateUrl: './nav-language.component.html',
  styleUrls: ['./nav-language.component.css']
})
export class NavLanguageComponent implements  OnInit{

  @Input() isFromHeader: boolean = false

  currentLanguage!: string;
  language = DisplayLanguage;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.currentLanguage = localStorage.getItem('default-lang') || DisplayLanguage.En;

  }

  changeLanguage(language: string): void {

    localStorage.setItem(AppItems.Lang, language);

    //for updating url language
    this.updateUrl(language)

    //this.snackBar.open(`Switched to ${language} language.`, 'Close', {
    //  duration: 2000,
    //});

    this.currentLanguage = language;
  }

  updateUrl(language: string) {

    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split('/');
    urlSegments[1] = language;
    const newUrl = urlSegments.join('/');

    if (this.isFromHeader) {
      this.router.navigate([newUrl])
        .then(() => {
          window.location.reload();

        });
    }
    else {
      window.location.reload();
    }
  }

}
