import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {BaseHelperComponent} from "../../../core/helper-components/base.helper-component";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {IdentityClient, Language, MembershipStatus, SignOutCommand} from "../../../web-api-client";
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import {AuthService} from "../../../providers/services/auth.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseHelperComponent implements OnInit {
  isSignedIn = false;
  lang!: Language
  membershipStatus = MembershipStatus.None;

  constructor(
    public authService: AuthService,
    private identityClient:IdentityClient,
    private dialog: MatDialog,
    private router: Router,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<DialogHeaderComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.lang = this.selectedLanguage
    this.isSignedIn = this.authService.getSignIn()
    console.log(this.isSignedIn)
  }

  navigate(link: string) {
    const url = this.currentLangUrl + link;
    this.router.navigate([url]);
  }

  public get getWidth() {
    return this._translateService.instant('header.subscribe').length > this._translateService.instant('btn.donate-now').length ?
      this._translateService.instant('header.subscribe').length * 10 + 15 : this._translateService.instant('btn.donate-now').length * 10 + 15;
  }


  openHeader() {
    const dialogRef = this.dialog.open(DialogHeaderComponent, {
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: '90vh',
      autoFocus: false,
      position: {
      top: '10px',
    }
    });
    this._subs.add(
      dialogRef.afterClosed()
        .subscribe({
          next: (isAccepted: any) => {
          },
          error: (error) => {
          }
        })
    )
  }

  signOut() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {autoFocus: false});
    this._subs.add(confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this.identityClient.signOut(new SignOutCommand()).subscribe(
            {
              next: (next) => {
                this.authService.logout()
                location.reload();
              }, error: (error) => {
                this.authService.logout()
                location.reload();
              }
            }
          ));
      }
    }));
  }


}
