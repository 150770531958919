<div>
  <div style="display:flex;justify-content:end">
    <app-nav-language [isFromHeader]="true"
                      style="margin-top:1.09px;"></app-nav-language>
    <mat-icon [mat-dialog-close]="true">clear</mat-icon>
  </div>
</div>

<mat-accordion multi>
  <mat-expansion-panel class="mat-elevation-z0" style="margin-top:20px;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'header.about-us' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="line-height:40px;">
      <p *ngFor="let item of subMenu">
        <span><a [href]="this.currentLangUrl + item.route">{{ item.label | translate}}</a></span>
        <mat-divider></mat-divider>
      </p>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngFor="let panel of panelData" [expanded]="false" hideToggle="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title (click)="$event.stopPropagation()">
        <a [href]="this.currentLangUrl + panel.route" routerLinkActive="active">{{ panel.label | translate }}</a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>


  <div class="buttons-header">
    <div>
      <button (click)="navigateComponent('/subscription-form/dialog')" mat-button type="submit" class="btn-orange">
        {{'header.subscribe' | translate}}
      </button>
    </div>

    <div>
      <button mat-raised-button (click)="navigateToDonation()" type="submit" class="btn-blue-shadow">
        {{'btn.donate-now' | translate}}
      </button>
    </div>
  </div>

</mat-accordion>
