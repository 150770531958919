import {Injectable, Injector, OnDestroy} from "@angular/core";
import {BehaviorSubject, interval, of, Subscription, switchMap, takeWhile} from "rxjs";
import {AuthClient, IdentityResponse, RefreshTokenCommand} from "../../web-api-client";
import jwt_decode from "jwt-decode";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService implements OnDestroy {

  private   _accessToken : string = "accessToken";
  private _refreshToken : string = "refreshToken";

  static  i = 1;
  private  isSignIn : BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);
  public accessTokenSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  private refreshTokenSubject: BehaviorSubject<string | undefined> =  new BehaviorSubject<string | undefined>(undefined);
  private refreshTokenTimerSubscription: Subscription | undefined ;

  constructor(
    private injector: Injector,
    private router: Router,
    private authClient: AuthClient) {


  }


  public logIstances(){
    const instances = this.injector.get(AuthService);
  }
  public setTokens(identityResponse: IdentityResponse) {
    if (identityResponse) {

      if(identityResponse.accessToken)
        localStorage.setItem(this._accessToken, identityResponse.accessToken);


      if(identityResponse.refreshToken)
        localStorage.setItem(this._refreshToken, identityResponse.refreshToken);

    //  this.accessTokenSubject.next(identityResponse.accessToken);
    //  this.refreshTokenSubject.next(identityResponse.refreshToken);

      this.startRefreshTokenTimer();
    }
  }

  public getAccessToken(){

     return localStorage.getItem(this._accessToken) ?? "";
  }

  public setSignIn(){
    this.isSignIn.next(true);
  }

  public getSignIn() {
    let accessToken = this.getAccessToken();

    if (accessToken != null) {
      if (accessToken.trim() !== '') {
        const decodedToken = this.decodeAccessToken(accessToken);
        return decodedToken != null;
      }
    }
    return false;
  }

  setAccessToken(token: string | undefined, startRefreshTimer: boolean = true) {
    localStorage.setItem(this._accessToken, token ?? '');
    if (startRefreshTimer) {
      this.startRefreshTokenTimer();
    }
  }

  public  isAccessTokenExpired(): boolean {
    const accessToken = this.getAccessToken()
    if (!accessToken) {
      return true; // Access token not available
    }
    const decodedToken: any = jwt_decode(accessToken);
    const expirationTime = decodedToken.exp * 1000; // Expiration time in milliseconds

    // Calculate the current time and the refresh threshold
    const currentTime = Date.now();
    const refreshThreshold = expirationTime - 1.8 * 60 * 1000; // Refresh 1.8 minutes before expiration

    return currentTime >= refreshThreshold;//k if current time is greater than or equal to expiration time
  }


  removeTokens() {
    localStorage.setItem(this._accessToken, "");
    localStorage.setItem(this._refreshToken, "");
  }



  logout(){
    this.removeTokens();
    this.stopTimer()
  }
  public startRefreshTokenTimer(){
      this.stopTimer()


    this.refreshTokenTimerSubscription = interval(60000) // Interval for checking token expiration (e.g., every 60 seconds)
      .pipe(
        takeWhile(() => !!this.getAccessToken()), // Continue until access token is available
        switchMap(() => {
          if (this.isAccessTokenExpired()) {
             let cmd = new RefreshTokenCommand();
             cmd.accessToken =  this.getAccessToken()
             cmd.refreshToken = this.getRefreshToken()
            // Access token expired, refresh it
            return  this.authClient.refreshToken(cmd);
          } else {
            // Access token not expired, emit null value
            return of(null);
          }
        })
      )
      .subscribe((response: any) => {
        if (response) {
          this.setTokens(response)
          // Update access token if refresh token was successful
        //  this.accessTokenSubject.next(response.accessToken);
        }
      });
  }

  private stopTimer(){
    if (this.refreshTokenTimerSubscription) {
      this.refreshTokenTimerSubscription.unsubscribe();
    }
  }
  ngOnDestroy(): void {
    if (this.refreshTokenTimerSubscription) {
      this.refreshTokenTimerSubscription.unsubscribe();
    }
  }

  navigateToLoginPage() {
    this.router.navigate(["/sign-in"]);
  }

  public getRefreshToken(){
    return localStorage.getItem(this._refreshToken) ?? "";
  }

  private decodeAccessToken(token: string): any {
    try {
      // Decode JWT token using jwt-decode library
      return jwt_decode(token);
    } catch (error) {
      // Error occurred while decoding token
      console.error("Error decoding token:", error);
      return null;
    }
  }
}
