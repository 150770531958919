import {Injectable} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import {IdentityService} from "../services/identity.service";
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: "root"})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private router: Router,
    private identityService: IdentityService,
    private authService : AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.authService.getSignIn()
    ) {
      return true;
    }

    this.authService.navigateToLoginPage();
    return false;
  }
}
