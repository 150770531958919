import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BaseHelperComponent } from "../../core/helper-components/base.helper-component";
import { Language } from "../../web-api-client";

@Injectable()
export class TitleService extends BaseHelperComponent {

  applicationName: string = ''

  constructor(private titleService: Title ) {
    super()
    this.applicationName = this.selectedLanguage == Language.En
      ? 'Union for Protection of the Interests and Rights of the Artsakh People'
      : this.selectedLanguage == Language.Hy
        ? 'Արցախի ժողովրդի շահերի ու իրավունքների պաշտպանության միություն'
        : 'Союз защиты интересов и прав народа Арцаха'
  }

  setTitleWithTranslateion(title: string) {
    const mappedTitle = this.titleMap[this.selectedLanguage]?.[title];
    this.titleService.setTitle(`${mappedTitle} | ${this.applicationName}`);
    console.log(mappedTitle);
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | ${this.applicationName}`);
  }

  setHomeTitle() {
    this.titleService.setTitle(this.applicationName);
  }

  titleMap: Record<Language, any> = {
    [Language.En]: {
      home: '',
      missionAndObjectives: 'Mission and objectives',
      statutes: 'Charter',
      news: 'News',
      membership: 'Application for Membership of the Artsakh Union',
      contactUs: 'Contact us',
      stayInTouch: 'Stay in Touch',
      staff: 'Management',
      privacyPolicy: 'Privacy policy',
      termsOfService:'Terms Of Service'
    },
    [Language.Hy]: {
      home: '',
      missionAndObjectives: 'Առաքելություն ու նպատակներ',
      statutes: 'Կանոնադրություն',
      news: 'Նորություններ',
      membership: 'Արցախ Միության անդամակցության հայտ',
      contactUs: 'Կապ',
      stayInTouch: 'Բաժանորդագրվեք',
      staff: 'Կառավարում',
      privacyPolicy: 'Գաղտնիության քաղաքականություն',
      termsOfService: 'Օգտագործման պայմաններ'
    },
    [Language.Ru]: {
      home: '',
      missionAndObjectives: 'Миссия и цели',
      statutes: 'Устав',
      news: 'Новости',
      membership: 'Заявка на членство в Арцахском союзе',
      contactUs: 'Связаться с нами',
      stayInTouch: 'Подписаться',
      staff: 'Управление',
      privacyPolicy: 'Политика конфиденциальности',
      termsOfService: 'Условия использования'
    },
    0: undefined
  };

}
