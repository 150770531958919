<mat-card>
  <p>{{"changePassword" | translate}}</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <input matInput formControlName="newPassword"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput formControlName="repeatPassword"/>
    </mat-form-field>
    <div>
      <button type="button" class="btn" (click)="close()">{{"close" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="disabled">{{"change" | translate}}</button>
    </div>
  </form>
</mat-card>
