<li [matMenuTriggerFor]="menu1">
  <a class="menu">
    <mat-icon style=" transform: scale(0.8);">language</mat-icon>
    <span class="uppercase" style="font-size:13px;">
      {{'header.currentLanguage' | translate}}
    </span>
  </a>
</li>
<mat-menu #menu1="matMenu">
  <button mat-menu-item (click)="changeLanguage(language.Hy)">Հայերեն</button>
  <button mat-menu-item (click)="changeLanguage(language.En)">English</button>
  <button mat-menu-item (click)="changeLanguage(language.Ru)">Русский</button>
</mat-menu>
