import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../../material.module';
import { SharedModule } from '../../../componenets/shared/shared.module';
import { TranslationService } from '../../../../providers/services/translation.service';
import { WelcomeComponent } from './welcome/welcome.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { NgImageSliderModule } from 'ng-image-slider';

const routes: Routes = [
  //{
  //  path: '',
  //  component: HomeComponent
  //},
  //{
  //  path: 'latest-news',
  //  component: LatestNewsComponent
  //},
  {
    path: 'objectives',
    component: ObjectivesComponent
  }
];

@NgModule({
  declarations: [
    WelcomeComponent,
    ObjectivesComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    NgImageSliderModule
  ],
  exports: [
    WelcomeComponent,
    ObjectivesComponent,
    NgImageSliderModule
  ],
  providers: [TranslationService]
})
export class MainPageModule { }
