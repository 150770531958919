<body>
  <div *ngIf="this.isLoading" class="progress-spinner">
    <mat-progress-spinner  [diameter]="60" [mode]="'indeterminate'" [value]="50"></mat-progress-spinner>
  </div>
  <div>
    <app-header></app-header>
    <div style="min-height: 100vh; flex-direction: column; display: flex;">
      <router-outlet></router-outlet>
      <app-scroll-top *ngIf="isMobileDevice"></app-scroll-top>
    </div>
    <app-footer style="justify-content: flex-end; margin: auto 0;" *ngIf="!isSignedIn"></app-footer>
  </div>
</body>
<notifier-container></notifier-container>
