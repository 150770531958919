import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {

  testingBool: boolean = false

  constructor(private translateService: TranslateService) { }

  getTranslation(message: string) {
    return this.translateService.instant(message)
  }
}
